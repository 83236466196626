import { RecaptchaForm } from './RecaptchaForm';

export interface DaycareForm extends RecaptchaForm {
    callDate?: string;
    fathersFullName?: string;
    fathersJob?: string;
    fathersJobPlace?: string;
    mothersFullName?: string;
    mothersJob?: string;
    mothersJobPlace?: string;
    childsFullName?: string;
    birthday?: string;
    admittanceDate?: string;
    daysPerWeek?: string;
    before7AM?: string;
    after6PM?: string;
    saturday?: string;
    address?: string;
    phoneNumber?: string;
    email?: string;
    daycare?: Daycare[];
    schedule: Schedule[];
    remarks?: string;
    contactRecipientEmailAddress?: string;
}

export interface Daycare {
    value?: string;
    label?: string;
}

export interface Schedule {
    index?: number;
    day?: string;
    start?: string;
    end?: string;
}