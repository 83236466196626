import React, { RefObject, useRef, useState } from 'react';
import { translate, WidgetHelper } from '../Helpers';
import * as Yup from 'yup';
import { GdprContactForm } from '../Models';
import ReCAPTCHA from 'react-google-recaptcha';
import { dictionaryKeys } from '../Constants';


interface GdprFormProps {
    formBgColorClass: string;
    formColorLabel: string;
    formButtonColorLabel: string;
    formButtonColorClass: string;
    formButtonHoverColorClass: string;
    formRequestTypeOptions: string[];
    googleRecaptchaSiteKey: string;
    captchaDataSize: "compact" | "normal" | "invisible" | undefined;
    globalSettingsId: string;
    contactRecipientEmailAddress: string;
}



export function GdprFormWidget(props: GdprFormProps) {
    const recaptchaRef: RefObject<ReCAPTCHA> = React.createRef();
    const validationSchema = useRef(
        Yup.object({
            requestType: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            requestMotivation: Yup.string().notRequired(),
            civility: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            firstName: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            lastName: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            birthDate: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            email: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField))
                .email(translate(dictionaryKeys.site.global.form.invalidEmail)),
            phone: Yup.string().notRequired(),
            address: Yup.string().notRequired(),
        })
    );

    const [requestType, setRequestType] = useState<string>(props.formRequestTypeOptions[0]);
    const [requestMotivation, setRequestMotivation] = useState<string>();
    const [civility, setCivility] = useState<string>();
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [birthDate, setBirthDate] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [phone, setPhone] = useState<string>();
    const [address, setAddress] = useState<string>();
    const [requestTypeError, setRequestTypeError] = useState<string>();
    const [civilityError, setCivilityError] = useState<string>();
    const [firstNameError, setFirstNameError] = useState<string>();
    const [lastNameError, setLastNameError] = useState<string>();
    const [birthDateError, setBirthDateError] = useState<string>();
    const [emailError, setEmailError] = useState<string>();
    const [recaptchaError, setRecaptchaError] = useState<string>();

    const [recaptchaToken, setRecaptchaToken] = useState<string>();
    const [statusMessage, setStatusMessage] = useState<string>();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    function executeApiRequest() {
        const formatedDataHeaders = new Headers();
        formatedDataHeaders.append("Accept-Language", document.querySelector("html")!.lang);
        formatedDataHeaders.append("Content-Type", "application/json");
        fetch(
            "/umbraco/api/contact/sendgdprcontactrequest",
            {
                headers: formatedDataHeaders,
                method: "post",
                body: JSON.stringify(getGpdrContactFormModel()),
            })
            .then((response) => {
                if (response.ok) {
                    setStatusMessage(translate(dictionaryKeys.site.global.form.successMessage));
                    setIsSuccess(true);
                    setIsError(false);
                }
                else {
                    setStatusMessage(translate(dictionaryKeys.site.global.form.errorOccured));
                    setIsSuccess(false);
                    setIsError(true);
                }
            });
    }

    function getGpdrContactFormModel(): GdprContactForm {
        return {
            requestType: requestType,
            requestMotivation: requestMotivation,
            civility: civility,
            firstName: firstName,
            lastName: lastName,
            birthDate: birthDate,
            email: email,
            phone: phone,
            address: address,
            recaptchaValue: recaptchaToken,
            globalSettingsId: props.globalSettingsId,
            contactRecipientEmailAddress: props.contactRecipientEmailAddress,
        }
    }

    function handleSubmit(e: any) {
        e.preventDefault();
        resetErrorMessages();
        resetSuccesInfo();

        if (props.googleRecaptchaSiteKey && !recaptchaToken) {
            resetRecatpcha();
            setRecaptchaError(translate(dictionaryKeys.site.global.form.requiredField))
            return;
        }

        validationSchema.current.validate(getGpdrContactFormModel(), { abortEarly: false })
            .then(() => {
                executeApiRequest();
            })
            .catch((validationError: Yup.ValidationError) => {
                resetRecatpcha()
                manageErrors(validationError);
            });
    }

    /*function executeRecaptcha() {
        recaptchaRef.current && recaptchaRef.current.execute();
    }*/

    function resetRecatpcha() {
        recaptchaRef.current && recaptchaRef.current.reset();
    }

    function manageErrors(validationError: Yup.ValidationError) {
        validationError.inner.forEach((error: Yup.ValidationError) => {
            switch (error.path) {
                case "requestType":
                    setRequestTypeError(error.message);
                    break;
                case "civility":
                    setCivilityError(error.message);
                    break;
                case "firstName":
                    setFirstNameError(error.message);
                    break;
                case "lastName":
                    setLastNameError(error.message);
                    break;
                case "birthDate":
                    setBirthDateError(error.message);
                    break;
                case "email":
                    setEmailError(error.message);
                    break;
            }
        });
    }

    function resetErrorMessages() {
        setRequestTypeError(undefined);
        setCivilityError(undefined);
        setFirstNameError(undefined);
        setLastNameError(undefined);
        setBirthDateError(undefined);
        setEmailError(undefined);
        setRecaptchaError(undefined);
    }

    function resetSuccesInfo() {
        setIsError(false);
        setIsSuccess(false);
        setStatusMessage(undefined);
    }

    function handleRecaptchaChange(recaptchaToken: string) {
        setRecaptchaToken(recaptchaToken);
    }

    return (
        <div className="form contact-form ajax-wrap">
            <div className={`inner ${props.formBgColorClass} ${props.formColorLabel}-text`}>
                <form onSubmit={handleSubmit}>
                    <div className="form-inner">
                        <div>
                            <fieldset>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="requestType" className="control-label">{translate(dictionaryKeys.site.gdprForm.requestType)} <em className="req">*</em></label>
                                        <select className="form-control" id="requestType" onChange={event => setRequestType(event.target.value)}>
                                            {props.formRequestTypeOptions.map((type, index) => <option key={index} value={type}>{type}</option>)}
                                        </select>
                                        {!!requestTypeError &&
                                            <span className="field-validation-error">{requestTypeError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="requestMotivation" className="control-label">{translate(dictionaryKeys.site.gdprForm.requestMotivation)}</label>
                                        <textarea className="form-control" id="requestMotivation" onChange={event => setRequestMotivation(event.target.value)}></textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="control-label">{translate(dictionaryKeys.site.gdprForm.civility)} <em className="req">*</em></label>
                                        <div className="form-group radio">
                                            <div className="form-control">
                                                <div className="radioRow">
                                                    <input id="W" name="civility" type="radio" value={translate(dictionaryKeys.site.global.form.civilityWoman)} onChange={event => setCivility(event.target.value)} />
                                                    <label htmlFor="W">{translate(dictionaryKeys.site.global.form.civilityWoman)}</label>
                                                </div>
                                                <div className="radioRow">
                                                    <input id="M" name="civility" type="radio" value={translate(dictionaryKeys.site.global.form.civilityMan)} onChange={event => setCivility(event.target.value)} />
                                                    <label htmlFor="M">{translate(dictionaryKeys.site.global.form.civilityMan)}</label>
                                                </div>
                                            </div>
                                        </div>
                                        {!!civilityError &&
                                            <span className="field-validation-error">{civilityError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="firstName" className="control-label">{translate(dictionaryKeys.site.gdprForm.lastName)} <em className="req">*</em></label>
                                        <input type="text" className="form-control" id="firstName" onChange={event => setFirstName(event.target.value)} />
                                        {!!firstNameError &&
                                            <span className="field-validation-error">{firstNameError}</span>
                                        }
                                    </div>
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="lastName" className="control-label">{translate(dictionaryKeys.site.gdprForm.firstName)} <em className="req">*</em></label>
                                        <input type="text" className="form-control" id="lastName" onChange={event => setLastName(event.target.value)} />
                                        {!!lastNameError &&
                                            <span className="field-validation-error">{lastNameError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="birthDate" className="control-label">{translate(dictionaryKeys.site.gdprForm.birthDate)} <em className="req">*</em></label>
                                        <input type="date" placeholder="jj/mm/aaaa" className="form-control" id="birthDate" onChange={event => setBirthDate(event.target.value)} />
                                        {!!birthDateError &&
                                            <span className="field-validation-error">{birthDateError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="email" className="control-label">{translate(dictionaryKeys.site.gdprForm.email)} <em className="req">*</em></label>
                                        <input type="email" autoCapitalize="off" className="form-control" id="email" onChange={event => setEmail(event.target.value)} />
                                        {!!emailError &&
                                            <span className="field-validation-error">{emailError}</span>
                                        }
                                    </div>
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="phone" className="control-label">{translate(dictionaryKeys.site.gdprForm.phone)}</label>
                                        <input type="tel" className="form-control" id="phone" onChange={event => setPhone(event.target.value)} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="address" className="control-label">{translate(dictionaryKeys.site.gdprForm.address)}</label>
                                        <textarea className="form-control" id="address" onChange={event => setAddress(event.target.value)} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-5 col-xs-5">
                                        {!!(props.googleRecaptchaSiteKey && props.captchaDataSize) &&
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                size={props.captchaDataSize}
                                                sitekey={props.googleRecaptchaSiteKey}
                                                onChange={handleRecaptchaChange}
                                            />
                                        }
                                        {!!recaptchaError &&
                                            <span className="field-validation-error">{recaptchaError}</span>
                                        }
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="buttons">
                                <button role="button"
                                    className={`btn ${props.formButtonColorClass} ${props.formButtonHoverColorClass}
                                              ${props.formButtonColorLabel}-btn-text ${props.formButtonColorLabel}-btn-borders`}
                                    type="submit">
                                    {translate(dictionaryKeys.site.global.form.submit)}
                                </button>
                            </fieldset>
                        </div>
                        {isSuccess &&
                            <div className="alert alert-success"><div className="info">{statusMessage}</div></div>
                        }
                        {isError &&
                            <div className="alert alert-warning"><div className="info">{statusMessage}</div></div>
                        }
                    </div>
                </form>
            </div>
        </div>
    );
}

WidgetHelper.register(GdprFormWidget, 'gdpr-form');