import { RecaptchaForm } from './RecaptchaForm';

export interface JobOfferForm extends RecaptchaForm {
    firstName?: string;
    lastName?: string;
    email?: string;
    dest: string;
    offerSubject: string;
    phone?: string;
    message?: string;
    cv?: string;
    motivation?: string;
    cvInfo?: string;
    motivationInfo?: string;
    source?: string;
}