import React, { useState, useRef, useEffect, useCallback } from 'react';
import { IMCForm } from '../Models'
import { translate, WidgetHelper } from '../Helpers';
import { dictionaryKeys } from '../Constants';
import * as Yup from 'yup';

interface ImcFormProps {
    formBgColorClass: string;
    formColorLabel: string;
    formButtonColorLabel: string;
    formButtonColorClass: string;
    formButtonHoverColorClass: string;
    formRequestTypeOptions: string[];
    googleRecaptchaSiteKey: string;
    captchaDataSize: "compact" | "normal" | "invisible" | undefined;
    globalSettingsId: string;
}

export function IMCFormWidget(props: ImcFormProps) {

    const validationSchema = useRef(
        Yup.object({
            height: Yup.number().min(1)
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            weight: Yup.number().min(1)
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
        })
    );


    const [height, setHeight] = useState<number>(0);
    const [weight, setWeight] = useState<number>(0);
    const [bmi, setBmi] = useState<string>();
    const [color, setColor] = useState<string>();
    const [heightError, setHeightError] = useState<string>();
    const [weightError, setWeightError] = useState<string>();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [statusMessage, setStatusMessage] = useState<string>();


    function getModel(): IMCForm {
        return {
            height: height,
            weight: weight
        }
    }

    function handleSubmit() {
        resetErrorMessages();
        resetSuccesInfo();

        validationSchema.current.validate(getModel(), { abortEarly: false })
            .then(() => {
                var b = weight / Math.pow(height, 2) * 10000;
                switch (true) {
                    case (b < 19): setColor("ResultOrange"); break;
                    case (b < 25): setColor("ResultGreen"); break;
                    case (b < 30): setColor("ResultOrange"); break;
                    default: setColor("ResultRed"); break;
                }
                setBmi(b.toString().substring(0, 5));
                console.log(b.toString());
            })
            .catch((validationError: Yup.ValidationError) => {
                console.log(validationError);
                manageErrors(validationError);
            });
    }

    function manageErrors(validationError: Yup.ValidationError) {
        validationError.inner.forEach((error: Yup.ValidationError) => {
            switch (error.path) {
                case "height":
                    setHeightError(error.message);
                    break;
                case "weight":
                    setWeightError(error.message);
                    break;
            }
        });
    }

    function resetSuccesInfo() {
        setIsError(false);
        setIsSuccess(false);
        setStatusMessage(undefined);
    }

    function resetErrorMessages() {
        setHeightError(undefined);
        setWeightError(undefined);
    }

    return (
        <div className="form-inner">
            <div className="container">
                <fieldset>
                    <div className="row justify-content-center">
                        <div className="form-group col-md-6 col-xs-12 align-middle text-center">
                            <div className="form-group">
                                <label htmlFor="height" className="control-label">{translate(dictionaryKeys.site.imcForm.height)} <em className="req">*</em></label>
                                <input type="number" min="1" max="250" className="form-control" id="height" onChange={event => setHeight(parseInt(event.target.value) || 0)} />
                                {!!heightError &&
                                    <span className="field-validation-error">{heightError}</span>
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="weight" className="control-label">{translate(dictionaryKeys.site.imcForm.weight)} <em className="req">*</em></label>
                                <input type="number" min="1" max="300" className="form-control" id="weight" onChange={event => setWeight(parseInt(event.target.value))} />
                                {!!weightError &&
                                    <span className="field-validation-error">{weightError}</span>
                                }
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset className="row">
                    <div className="form-group col-12 align-middle text-center">
                        <button role="button" type='button'
                            className={`btn ${props.formButtonColorClass} ${props.formButtonHoverColorClass}
                                              ${props.formButtonColorLabel}-btn-text ${props.formButtonColorLabel}-btn-borders`}
                            onClick={() => handleSubmit()}>
                            {translate(dictionaryKeys.site.imcForm.calcul)}
                        </button>
                    </div>
                </fieldset>
                <fieldset className="row">
                    <div className="form-group col-12 align-middle text-center">

                        {!!bmi &&
                            <span className={color + " display-4"}>Votre IMC : {bmi}</span>
                        }
                    </div>
                </fieldset>
            </div>
            {isSuccess &&
                <div className="alert alert-success"><div className="info">{statusMessage}</div></div>
            }
            {isError &&
                <div className="alert alert-warning"><div className="info">{statusMessage}</div></div>
            }
        </div>
    );
};



WidgetHelper.register(IMCFormWidget, 'imc-form');