import { RecaptchaForm } from './RecaptchaForm';

export interface RdvForm extends RecaptchaForm {
    doctor?: string;
    branche?: string;
    firstName?: string;
    lastName?: string;
    birthDate?: string;
    email?: string;
    phone?: string;
    requestType?: string;
    comment?: string;
    contactRecipientEmailAddress?: string;
}