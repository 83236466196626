export const constants =
{
    site: {
        global: {
            form: {
                hhmm_ph: "hh:mm"
            }
        },

        gdprForm: {
        },

        contactForm: {
        },

        rdvForm: {
        },

        jobForm: {
            allowExt: ["image/*", ".pdf", ".doc", ".docx", '.odt'],
            jobType_ph: "Kine, Medecin, ...",
        },

        aesForm: {
            children: { min: 1, majority: 18 },
            periods: { min: 1 }
        }
    }
}