let translations: { [key: string]: string };

export function translate(key: string) {
    if (!translations)
    {
        const translationScripts = document.getElementsByClassName('translation-script');
        translations = {};

        for (const translationScript of Array.from(translationScripts))
        {
            const items = JSON.parse(translationScript.innerHTML);
            translations = { ...translations, ...items };
            translationScript.remove();
        }

    }
    return translations[key];
}