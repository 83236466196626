import React, { RefObject, useRef, useState } from 'react';
import ReactFileReader from 'react-file-reader';
import { translate, WidgetHelper } from '../Helpers';
import * as Yup from 'yup';
import { JobForm } from '../Models';
import ReCAPTCHA from 'react-google-recaptcha';
import { dictionaryKeys, constants } from '../Constants';

interface JobFormProps {
    formBgColorClass: string;
    formColorLabel: string;
    formButtonColorLabel: string;
    formButtonColorClass: string;
    formButtonHoverColorClass: string;
    googleRecaptchaSiteKey: string;
    captchaDataSize: "compact" | "normal" | "invisible" | undefined;
    globalSettingsId: string;
    contactRecipientEmailAddress: string;
    contactRecipientEmailAddressPara: string;
}

export function JobFormWidget(props: JobFormProps) {

    const recaptchaRef: RefObject<ReCAPTCHA> = React.createRef();
    const validationSchema = useRef(
        Yup.object({
            jobType: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            cv: Yup.string().required(translate(dictionaryKeys.site.global.form.requiredField)),
            motivation: Yup.string().required(translate(dictionaryKeys.site.global.form.requiredField)),
            message: Yup.string().required(translate(dictionaryKeys.site.global.form.requiredField)),
            firstName: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            lastName: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            email: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField))
                .email(translate(dictionaryKeys.site.global.form.invalidEmail)),
            phone: Yup.string().required(translate(dictionaryKeys.site.global.form.requiredField)),
            sector: Yup.string().required(translate(dictionaryKeys.site.global.form.requiredField)),
        })
    );

    const [jobType, setJobType] = useState<string>();
    const [sector, setSector] = useState<string>();
    const [cv, setCv] = useState<string>();
    const [motivation, setMotivation] = useState<string>();
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [phone, setPhone] = useState<string>();
    const [message, setMessage] = useState<string>();

    const [sectorError, setSectorError] = useState<string>();
    const [jobTypeError, setJobTypeError] = useState<string>();
    const [firstNameError, setFirstNameError] = useState<string>();
    const [lastNameError, setLastNameError] = useState<string>();
    const [phoneError, setPhoneError] = useState<string>();
    const [emailError, setEmailError] = useState<string>();
    const [messageError, setMessageError] = useState<string>();
    const [cvError, setCvError] = useState<string>();
    const [motivationError, setMotivationError] = useState<string>();

    const [cvInfo, setCvInfo] = useState<string>();
    const [motivationInfo, setMotivationInfo] = useState<string>();

    const [recaptchaError, setRecaptchaError] = useState<string>();
    const [recaptchaToken, setRecaptchaToken] = useState<string>();
    const [statusMessage, setStatusMessage] = useState<string>();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isCv, setIsCv] = useState(false);
    const [isMotivation, setIsMotivation] = useState(false);

    function executeApiRequest() {
        const formatedDataHeaders = new Headers();
        formatedDataHeaders.append("Accept-Language", document.querySelector("html")!.lang);
        formatedDataHeaders.append("Content-Type", "application/json");
        fetch(
            "/umbraco/api/contact/sendjobrequest",
            {
                headers: formatedDataHeaders,
                method: "post",
                body: JSON.stringify(getJobFormModel()),
            })
            .then((response) => {
                if (response.ok) {
                    setStatusMessage(translate(dictionaryKeys.site.global.form.successMessage));
                    setIsSuccess(true);
                    setIsError(false);
                }
                else {
                    setStatusMessage(translate(dictionaryKeys.site.global.form.errorOccured));
                    setIsSuccess(false);
                    setIsError(true);
                }
            });
    }

    function getJobFormModel(): JobForm {
        return {
            jobType: jobType,
            cv: cv,
            motivation: motivation,
            sector: sector,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            message: message,
            cvInfo: cvInfo,
            motivationInfo: motivationInfo,
            recaptchaValue: recaptchaToken,
            globalSettingsId: props.globalSettingsId,
            contactRecipientEmailAddress: props.contactRecipientEmailAddress,
            contactRecipientEmailAddressPara: props.contactRecipientEmailAddressPara,
        }
    }

    function handleSubmit(e: any) {
        e.preventDefault();
        resetErrorMessages();
        resetSuccesInfo();

        if (props.googleRecaptchaSiteKey && !recaptchaToken) {
            resetRecatpcha();
            setRecaptchaError(translate(dictionaryKeys.site.global.form.requiredField))
            return;
        }

        validationSchema.current.validate(getJobFormModel(), { abortEarly: false })
            .then(() => 
            {
                executeApiRequest();
            })
            .catch((validationError: Yup.ValidationError) => {
                resetRecatpcha()
                manageErrors(validationError);
            });
    }

    function resetRecatpcha() {
        recaptchaRef.current && recaptchaRef.current.reset();
    }

    function manageErrors(validationError: Yup.ValidationError) {
        validationError.inner.forEach((error: Yup.ValidationError) => {
            switch (error.path) {
                case "sector":
                    setSectorError(error.message);
                    break;
                case "jobType":
                    setJobTypeError(error.message);
                    break;
                case "firstName":
                    setFirstNameError(error.message);
                    break;
                case "lastName":
                    setLastNameError(error.message);
                    break;
                case "email":
                    setEmailError(error.message);
                    break;
                case "phone":
                    setPhoneError(error.message);
                    break;
                case "cv":
                    setCvError(error.message);
                    break;
                case "motivation":
                    setMotivationError(error.message);
                    break;
                case "message":
                    setMessageError(error.message);
                    break;
            }
        });
    }

    function resetErrorMessages() {
        setSectorError(undefined);
        setJobTypeError(undefined);
        setFirstNameError(undefined);
        setLastNameError(undefined);
        setPhoneError(undefined);
        setEmailError(undefined);
        setMessageError(undefined);
        setCvError(undefined);
        setMotivationError(undefined);
        setRecaptchaError(undefined);
    }

    function resetSuccesInfo() {
        setIsError(false);
        setIsSuccess(false);
        setStatusMessage(undefined);
    }

    function handleRecaptchaChange(recaptchaToken: string) {
        setRecaptchaToken(recaptchaToken);
    }

    function handleCV(files:any) {
        setCv(files.base64)
        setCvError(undefined);
        setIsCv(true);
        setCvInfo(files.fileList[0].name);
    }
    function handleMotivation(files:any) {
        setMotivation(files.base64)
        setMotivationError(undefined);
        setIsMotivation(true);
        setMotivationInfo(files.fileList[0].name);
    }
    return (
        <div className="form contact-form ajax-wrap">
            <div className={`inner ${props.formBgColorClass} ${props.formColorLabel}-text`}>
                <form onSubmit={handleSubmit}>
                    <div className="form-inner">
                        <div className="container">
                            <fieldset>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="control-label">{translate(dictionaryKeys.site.jobForm.sector)} <em className="req">*</em></label>
                                        <div className="form-group radio">
                                            <div className="form-control">
                                                <div className="radioRow">
                                                    <input id="M" name="sector" type="radio" value={translate(dictionaryKeys.site.jobForm.sectorMedical)} onChange={event => setSector(event.target.value)} />
                                                    <label htmlFor="M">{translate(dictionaryKeys.site.jobForm.sectorMedical)}</label>
                                                </div>
                                                <div className="radioRow">
                                                    <input id="P" name="sector" type="radio" value={translate(dictionaryKeys.site.jobForm.sectorParaMedical)} onChange={event => setSector(event.target.value)} />
                                                    <label htmlFor="P">{translate(dictionaryKeys.site.jobForm.sectorParaMedical)}</label>
                                                </div>
                                            </div>
                                        </div>
                                        {!!sectorError &&
                                            <span className="field-validation-error">{sectorError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="jobType" className="control-label">{translate(dictionaryKeys.site.jobForm.jobType)} <em className="req">*</em></label>
                                        <input type="text" className="form-control" placeholder={constants.site.jobForm.jobType_ph} id="jobType" onChange={event => setJobType(event.target.value)} />
                                        {!!jobTypeError &&
                                            <span className="field-validation-error">{jobTypeError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="firstName" className="control-label">{translate(dictionaryKeys.site.jobForm.lastName)} <em className="req">*</em></label>
                                        <input type="text" className="form-control" id="firstName" onChange={event => setFirstName(event.target.value)} />
                                        {!!firstNameError &&
                                            <span className="field-validation-error">{firstNameError}</span>
                                        }
                                    </div>
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="lastName" className="control-label">{translate(dictionaryKeys.site.jobForm.firstName)} <em className="req">*</em></label>
                                        <input type="text" className="form-control" id="lastName" onChange={event => setLastName(event.target.value)} />
                                        {!!lastNameError &&
                                            <span className="field-validation-error">{lastNameError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="email" className="control-label">{translate(dictionaryKeys.site.jobForm.email)} <em className="req">*</em></label>
                                        <input type="email" autoCapitalize="off" className="form-control" id="email" onChange={event => setEmail(event.target.value)} />
                                        {!!emailError &&
                                            <span className="field-validation-error">{emailError}</span>
                                        }
                                    </div>
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="phone" className="control-label">{translate(dictionaryKeys.site.jobForm.phone)} <em className="req">*</em></label>
                                        <input type="tel" className="form-control" id="phone" onChange={event => setPhone(event.target.value)} />
                                        {!!phoneError &&
                                            <span className="field-validation-error">{phoneError}</span>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <ReactFileReader handleFiles={handleCV} fileTypes={constants.site.jobForm.allowExt} base64={true}>
                                        <button role="button" title={constants.site.jobForm.allowExt.toString()} type="button" className={`btn ${props.formButtonColorClass} ${props.formButtonHoverColorClass}
                                            ${props.formButtonColorLabel}-btn-text ${props.formButtonColorLabel}-btn-borders`}>
                                            {translate(dictionaryKeys.site.jobForm.cv_btn)} <em className="req">*</em>
                                        </button>
                                    </ReactFileReader>
                                    {!!cvError &&
                                        <span className="field-validation-error">{cvError}</span>
                                    }
                                    {!!isCv &&
                                        <span className="base-link">{cvInfo}</span>
                                    }
                                    <ReactFileReader handleFiles={handleMotivation} fileTypes={constants.site.jobForm.allowExt} base64={true}>
                                        <button role="button" title={constants.site.jobForm.allowExt.toString()} type="button" className={`btn ${props.formButtonColorClass} ${props.formButtonHoverColorClass}
                                        ${props.formButtonColorLabel}-btn-text ${props.formButtonColorLabel}-btn-borders`}>
                                            {translate(dictionaryKeys.site.jobForm.motivation_btn)} <em className="req">*</em>
                                        </button>
                                        <span className="control-label"></span>
                                    </ReactFileReader>
                                   
                                    {!!motivationError &&
                                        <span className="field-validation-error">{motivationError}</span>
                                    }
                                    {!!isMotivation &&
                                        <span className="base-link">{motivationInfo}</span>
                                    }
                                </div>
                                <br />
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="message" className="control-label">{translate(dictionaryKeys.site.jobForm.message)} <em className="req">*</em></label>
                                        <textarea className="form-control" id="message" onChange={event => setMessage(event.target.value)}></textarea>
                                        {!!messageError &&
                                            <span className="field-validation-error">{messageError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-5 col-xs-5">
                                        {!!(props.googleRecaptchaSiteKey && props.captchaDataSize) &&
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                size={props.captchaDataSize}
                                                sitekey={props.googleRecaptchaSiteKey}
                                                onChange={handleRecaptchaChange}
                                            />
                                        }
                                        {!!recaptchaError &&
                                            <span className="field-validation-error">{recaptchaError}</span>
                                        }
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="buttons">
                                <button role="button"
                                    className={`btn ${props.formButtonColorClass} ${props.formButtonHoverColorClass}
                                              ${props.formButtonColorLabel}-btn-text ${props.formButtonColorLabel}-btn-borders`}
                                    type="submit">
                                {translate(dictionaryKeys.site.global.form.submit)}
                                </button>
                            </fieldset>
                        </div>
                        {isSuccess && 
                            <div className="alert alert-success"><div className="info">{statusMessage}</div></div>
                        }
                        {isError && 
                            <div className="alert alert-warning"><div className="info">{statusMessage}</div></div>
                        }
                    </div>
                </form>
            </div>
        </div>
    );
}

WidgetHelper.register(JobFormWidget, 'job-form');