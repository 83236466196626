import React, { useState, useRef, useEffect } from 'react';
import { translate, WidgetHelper } from '../Helpers';
import { PublicationModel, Publication } from '../Models';
import { dictionaryKeys } from '../Constants';

interface PublicationProps {
    formBgColorClass: string;
    formColorLabel: string;
    formButtonColorLabel: string;
    formButtonColorClass: string;
    formButtonHoverColorClass: string;
    globalSettingsId: string;
}

export function PublicationWidget(props: PublicationProps) {

    const [info, setInfo] = useState<string>();
    const [pub, setPub] = useState<PublicationModel[]>();
    const [service, setService] = useState({ status: "init" });
    
    const mounted = useRef(true);

    var stringToHTML = function (str:string) {
        return new DOMParser()
            .parseFromString(str, "text/html")
            .documentElement.textContent;
    };

    useEffect(() => {
        const formatedDataHeaders = new Headers();
        formatedDataHeaders.append("Accept-Language", document.querySelector("html")!.lang);
        formatedDataHeaders.append("Content-Type", "application/json");
        setService({ status: "Loading" })
        fetch(
            "/umbraco/api/publication/getPublications",
            {
                headers: formatedDataHeaders,
                method: "POST",

            })
            .then((resp) => {
                if (!resp.ok) {
                    throw Error(resp.statusText);
                }
                return resp.json()
            })
            .then((data) => {
                if (mounted.current) {
                    const pm = data as PublicationModel[];
                    setPub(pm);
                    setService({ status: (typeof pm === "undefined" ? "Empty" : "Loaded") });
                }
            }).catch(function (error) {
                console.error(error);
                setService({ status: "Error" });
                setInfo(error.message);
            });
        return () => {
            mounted.current = false;
        }
    }, [])

    return (
        <div className="form publication-form ajax-wrap">
            <div className={`inner ${props.formBgColorClass} ${props.formColorLabel}-text`}>
                <div className="form-inner">
                    <div className="container">
                        <h1 className="heading main base-heading">{translate(dictionaryKeys.site.publicationForm.label)}</h1>
                        <fieldset>
                                <div className="form-group col-12">
                                    {service.status === "Loading" &&
                                        <div className="alert alert-info"><div className="info">{translate(dictionaryKeys.site.global.form.loading)}</div></div>
                                    }
                                    {service.status === "Error" &&
                                        <div className="alert alert-warning"><div className="info">{translate(dictionaryKeys.site.global.form.errorOccured)} {info}</div></div>
                                    }
                                    {service.status === "Empty" &&
                                        <div className="alert alert-info"><div className="info">{translate(dictionaryKeys.site.global.form.emptyData)}</div></div>
                                    }
                                </div>

                                <div className="repeatable tabbed tab-basic " data-os-animation="fadeIn" data-os-animation-delay="0s" data-os-animation-duration="1,2s">
                                    <nav className="tabs">
                                        <ul className="nav" role="tablist">
                                        {service.status === "Loaded" &&
                                            pub!.map((p: PublicationModel, idx) => (
                                                <li className="tab" role="presentation">
                                                    <a className={"nav-item nav-button-link nav-link " + (idx === 0 ? "active" : "")} id={"nav_tab_" + idx} key={"nav_tab_" + idx}
                                                        data-toggle="tab" href={"#nav_" + idx} role="tab" aria-controls={"nav_" + idx} aria-selected={idx === 0}>
                                                        {p.Annee}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                    <div id="nav-tabContent" className="repeatable-content tab-content">
                                        {service.status === "Loaded" &&
                                            pub!.map((p: PublicationModel, idx) => (
                                                <div className={"info tab-pane fade " + (idx === 0 ? "show active" : "")} id={"nav_" + idx} key={"nav_" + idx} role="tabpanel" aria-labelledby={"nav_tab_" + idx}>
                                                    <ul>
                                                        {p.Publications.map((item: Publication) => (
                                                            <li style={{paddingBottom:"10px"}}>
                                                            <b>{stringToHTML(item.Titre)}</b><br /><em>{stringToHTML(item.Auteurs)}</em><br />
                                                        </li>
                                                    ))}
                                                    </ul>
                                                </div>
                                        ))}
                                    </div>
                                </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    );
}

WidgetHelper.register(PublicationWidget, 'publication-form');