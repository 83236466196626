export const dictionaryKeys =
{
    site: {
        routing: {
            consultations: "Site_Routing_Consultations"
        },
        global: {
            form: {
                add: "Site_Global_Form_Add",
                delete: "Site_Global_Form_Delete",
                yes: "Site_Global_Form_Yes",
                no: "Site_Global_Form_No",
                civilityBoy: "Site_Global_Form_CivilityBoy",
                civilityGirl: "Site_Global_Form_CivilityGirl",
                civilityMan: "Site_Global_Form_CivilityMan",
                civilityWoman: "Site_Global_Form_CivilityWoman",
                errorOccured: "Site_Global_Form_ErrorOccured",
                invalidEmail: "Site_Global_Form_InvalidEmail",
                requiredField: "Site_Global_Form_RequiredField",
                submit: "Site_Global_Form_Submit",
                search: "Site_Global_Form_Search",
                successMessage: "Site_Global_Form_SuccessMessage",
                emptyData: "Site_Global_Form_EmptyData",
                loading: "Site_Global_Form_Loading",
                day: "Site_Global_Form_Day",
                dayMorning: "Site_Global_Form_Day_Morning",
                dayAfternoon: "Site_Global_Form_Day_Afternoon",
                days: {
                    abreviation: {
                        monday: "Site_Global_Form_Days_Abreviation_Monday",
                        tuesday: "Site_Global_Form_Days_Abreviation_Tuesday",
                        wednesday: "Site_Global_Form_Days_Abreviation_Wednesday",
                        thursday: "Site_Global_Form_Days_Abreviation_Thursday",
                        friday: "Site_Global_Form_Days_Abreviation_Friday",
                        saturday: "Site_Global_Form_Days_Abreviation_Saturday",
                        sunday: "Site_Global_Form_Days_Abreviation_Sunday",
                    },
                    monday: "Site_Global_Form_Days_Monday",
                    tuesday: "Site_Global_Form_Days_Tuesday",
                    wednesday: "Site_Global_Form_Days_Wednesday",
                    thursday: "Site_Global_Form_Days_Thursday",
                    friday: "Site_Global_Form_Days_Friday",
                    saturday: "Site_Global_Form_Days_Saturday",
                    sunday: "Site_Global_Form_Days_Sunday",
                },
                date: {
                    greaterThan: "Site_Global_Form_Date_GreaterThan",
                    lessThan: "Site_Global_Form_Date_LessThan",
                    greaterThanDay: "Site_Global_Form_Date_GreaterThanDay",
                    lessThanDay: "Site_Global_Form_Date_GreaterThanDay",
                },
            }
        },
        imcForm: {
            bmi: "Site_IMCForm_BMI",
            weight: "Site_IMCForm_Weight",
            height: "Site_IMCForm_Height",
            calcul: "Site_IMCForm_Calcul",
        },
        publicationForm: {
            year: "Site_PublicationForm_Year",
            title: "Site_PublicationForm_Title",
            author: "Site_PublicationForm_Author",
            type: "Site_PublicationForm_Type",
            document: "Site_PublicationForm_Document",
            label: "Site_PublicationForm_Label",
        },
        consultationForm: {
            doctor: "Site_ConsultationForm_Doctor",
            conventionne: "Site_ConsultationForm_Conventionne",
            nonConventionne: "Site_ConsultationForm_NonConventionne",
            consultation: "Site_ConsultationForm_Consultation",
        },
        gdprForm: {
            address: "Site_GdprForm_Address",
            birthDate: "Site_GdprForm_BirthDate",
            civility: "Site_GdprForm_Civility",
            email: "Site_GdprForm_Email",
            firstName: "Site_GdprForm_FirstName",
            lastName: "Site_GdprForm_LastName",
            phone: "Site_GdprForm_Phone",
            requestMotivation: "Site_GdprForm_RequestMotivation",
            requestType: "Site_GdprForm_RequestType",
        },

        contactForm: {
            address: "Site_ContactForm_Address",
            email: "Site_ContactForm_Email",
            firstName: "Site_ContactForm_FirstName",
            lastName: "Site_ContactForm_LastName",
            phone: "Site_ContactForm_Phone",
            message: "Site_ContactForm_Message",
            zipCode: "Site_ContactForm_ZipCode",
            town: "Site_ContactForm_Town",
        },

        rdvForm: {
            doctor: "Site_RdvForm_Doctor",
            branche: "Site_RdvForm_Branche",
            requestType: "Site_RdvForm_RequestType",
            requestTypeCancel: "Site_RdvForm_RequestType_Cancel",
            requestTypeRequest: "Site_RdvForm_RequestType_Request",
            email: "Site_RdvForm_Email",
            firstName: "Site_RdvForm_FirstName",
            lastName: "Site_RdvForm_LastName",
            birthDate: "Site_RdvForm_BirthDate",
            phone: "Site_RdvForm_Phone",
            comment: "Site_RdvForm_Comment",
            successMessage: "Site_RdvForm_SuccessMessage",
        },

        jobForm: {
            sector: "Site_JobForm_Sector",
            sectorMedical: "Site_JobForm_Sector_Medical",
            sectorParaMedical: "Site_JobForm_Sector_ParaMedical",
            jobType: "Site_JobForm_JobType",
            email: "Site_JobForm_Email",
            firstName: "Site_JobForm_FirstName",
            lastName: "Site_JobForm_LastName",
            phone: "Site_JobForm_Phone",
            message: "Site_JobForm_Message",
            cv: "Site_JobForm_CV",
            motivation: "Site_JobForm_Motivation",
            cv_btn: "Site_JobForm_CvButton",
            motivation_btn: "Site_JobForm_MotivationButton"
        },

        jobOfferForm: {
            sector: "Site_JobOfferForm_Sector",
            jobType: "Site_JobOfferForm_JobType",
            email: "Site_JobOfferForm_Email",
            firstName: "Site_JobOfferForm_FirstName",
            lastName: "Site_JobOfferForm_LastName",
            phone: "Site_JobOfferForm_Phone",
            message: "Site_JobOfferForm_Message",
            cv: "Site_JobOfferForm_CV",
            motivation: "Site_JobOfferForm_Motivation",
            cv_btn: "Site_JobOfferForm_CvButton",
            motivation_btn: "Site_JobOfferForm_MotivationButton",
            source: "Site_JobOfferForm_Source",
            other: "Site_JobOfferForm_Other"
        },

        stageAesForm: {
            childrenCountError: "Site_StageAesForm_ChildrenCountError",
            title: "Site_StageAesForm_Title",
            stageTitle: "Site_StageAesForm_StageTitle",
            address: "Site_StageAesForm_Address",
            civility: "Site_StageAesForm_Civility",
            authorized: "Site_StageAesForm_Authorized",
            authorizedInfo: "Site_StageAesForm_Authorized_Info",
            children: "Site_StageAesForm_Children",
            childrenFirstName: "Site_StageAesForm_Children_FirstName",
            childrenLastName: "Site_StageAesForm_Children_LastName",
            childrenBirthDate: "Site_StageAesForm_Children_BirthDate",
            childrenGender: "Site_StageAesForm_Children_Gender",
            childrenAllergy: "Site_StageAesForm_Children_Allergy",
            childrenAllergy_ph: "Site_StageAesForm_Children_Allergy_PlaceHolder",
            email: "Site_StageAesForm_Email",
            firstName: "Site_StageAesForm_FirstName",
            lastName: "Site_StageAesForm_LastName",
            phone: "Site_StageAesForm_Phone",
            zipCode: "Site_StageAesForm_ZipCode",
            town: "Site_StageAesForm_Town",
            comment: "Site_StageAesForm_Comment",
            nursery: "Site_StageAesForm_Nursery",
            xxlFamily: "Site_StageAesForm_XxlFamily",
            isIsppc: "Site_StageAesForm_IsIsppc",
            place: "Site_StageAesForm_Place",
            period: "Site_StageAesForm_Period",
        },
        
        daycareForm: {
            fathersFullName: "Site_DaycareForm_FathersFullName",
            fathersJob: "Site_DaycareForm_FathersJob",
            fathersJobPlace: "Site_DaycareForm_FathersJobPlace",
            mothersFullName: "Site_DaycareForm_MothersFullName",
            mothersJob: "Site_DaycareForm_MothersJob",
            mothersJobPlace: "Site_DaycareForm_MothersJobPlace",
            childsFullName: "Site_DaycareForm_ChildsFullName",
            birthday: "Site_DaycareForm_Birthday",
            admittanceDate: "Site_DaycareForm_AdmittanceDate",
            daysPerWeek: "Site_DaycareForm_DaysPerWeek",
            callDate: "Site_DaycareForm_CallDate",
            address: "Site_DaycareForm_Address",
            email: "Site_DaycareForm_Email",
            phoneNumber: "Site_DaycareForm_PhoneNumber",
            before7AM: "Site_DaycareForm_Before7AM",
            after6PM: "Site_DaycareForm_After6PM",
            saturday: "Site_DaycareForm_Saturday",
            daycare: "Site_DaycareForm_Daycare",
            schedule: "Site_DaycareForm_Schedule",
            scheduleError: "Site_DaycareForm_ScheduleError",
            remarks: "Site_DaycareForm_Remarks",
            arrivalTime: "Site_DaycareForm_ArrivalTime",
            departureTime: "Site_DaycareForm_DepartureTime",
        },
        roadTable: {
            dest: "Site_RoadTable_Dest",
            num: "Site_RoadTable_Num",
            typ: "Site_RoadTable_Typ",
        }

        
    }
}