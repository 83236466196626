import { RecaptchaForm } from './RecaptchaForm';

export interface GdprContactForm extends RecaptchaForm {
    requestType?: string;
    requestMotivation?: string;
    civility?: string;
    firstName?: string;
    lastName?: string;
    birthDate?: string;
    email?: string;
    phone?: string;
    address?: string;
    contactRecipientEmailAddress?: string;
}