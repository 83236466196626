import { RecaptchaForm } from './RecaptchaForm';

export interface ContactForm extends RecaptchaForm {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    address?: string;
    zipCode?: string;
    town?: string;
    message?: string;
    contactRecipientEmailAddress?: string;
}