import React from 'react';
import ReactDOM from 'react-dom';

export class WidgetHelper {
    public static register<P extends {}>(component: React.FunctionComponent<P> | React.ComponentClass<P> | string, selectors: string) {
        const hosts = document.querySelectorAll(selectors);
        for (let i = 0, c = hosts.length; i < c; i++) {
            let host = hosts[i] as HTMLElement;
            let domChildren: Element[] = [];
            for (let j = 0, jc = host.children.length; j < jc; j++) {
                domChildren.push(host.children[j]);
            }

            const jsonTag = host.querySelector('script[type="application/json"]:not(.translation-script)');
            const jsonData = jsonTag ? JSON.parse(jsonTag.innerHTML) : {};

            var data: any = { ...host.dataset, ...jsonData, domChildren };
            ReactDOM.render(React.createElement(component, data), host);
            host.classList.add('widget-loaded');
        }
    }
}