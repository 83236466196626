export interface PublicationModel {
    Annee: number;
    Publications: Publication[];
}
export interface Publication {
    IdPublication: number;
    Auteurs: string;
    Titre: string;
    HasDocument: boolean;
    Type: string;
}
