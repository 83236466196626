import React, { RefObject, useRef, useState } from 'react';
import { translate, WidgetHelper } from '../Helpers';
import * as Yup from 'yup';
import { RdvForm } from '../Models';
import ReCAPTCHA from 'react-google-recaptcha';
import { dictionaryKeys } from '../Constants';

interface RdvFormProps {
    formBgColorClass: string;
    formColorLabel: string;
    formButtonColorLabel: string;
    formButtonColorClass: string;
    formButtonHoverColorClass: string;
    formRequestTypeOptions: string[];
    googleRecaptchaSiteKey: string;
    captchaDataSize: "compact" | "normal" | "invisible" | undefined;
    globalSettingsId: string;
    contactRecipientEmailAddress: string;
}

export function RdvFormWidget(props: RdvFormProps) {

    const recaptchaRef: RefObject<ReCAPTCHA> = React.createRef();
    const validationSchema = useRef(
        Yup.object({
            requestType: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            doctor: Yup.string().notRequired(),
            branche: Yup.string().notRequired(),
            comment: Yup.string().notRequired(),
            firstName: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            lastName: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            birthDate: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            email: Yup.string()
                .notRequired()
                .email(translate(dictionaryKeys.site.global.form.invalidEmail)),
            phone: Yup.string().required(translate(dictionaryKeys.site.global.form.requiredField)),
            address: Yup.string().notRequired(),
        })
    );
    const [requestIndex, setRequestIndex] = useState(0);
    const [requestType, setRequestType] = useState<string>();
    const [doctor, setDoctor] = useState<string>();
    const [branche, setBranche] = useState<string>();
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [birthDate, setBirthDate] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [phone, setPhone] = useState<string>();
    const [comment, setComment] = useState<string>();
    const [requestTypeError, setRequestTypeError] = useState<string>();
    const [firstNameError, setFirstNameError] = useState<string>();
    const [lastNameError, setLastNameError] = useState<string>();
    const [birthDateError, setBirthDateError] = useState<string>();
    const [phoneError, setPhoneError] = useState<string>();
    const [emailError, setEmailError] = useState<string>();

    const [recaptchaError, setRecaptchaError] = useState<string>();
    const [recaptchaToken, setRecaptchaToken] = useState<string>();
    const [statusMessage, setStatusMessage] = useState<string>();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);


    function executeApiRequest() {
        const formatedDataHeaders = new Headers();
        formatedDataHeaders.append("Accept-Language", document.querySelector("html")!.lang);
        formatedDataHeaders.append("Content-Type", "application/json");
        fetch(
            requestIndex == 1 ? "/umbraco/api/contact/sendrdvrequest" : "/umbraco/api/contact/sendrdvcancelrequest",
            
            {
                headers: formatedDataHeaders,
                method: "post",
                body: JSON.stringify(getRdvFormModel()),
            })
            .then((response) => {
                if (response.ok) {
                    setStatusMessage(translate(dictionaryKeys.site.rdvForm.successMessage));
                    setIsSuccess(true);
                    setIsError(false);
                }
                else {
                    setStatusMessage(translate(dictionaryKeys.site.global.form.errorOccured));
                    setIsSuccess(false);
                    setIsError(true);
                }
                resetRecatpcha();
            });
    }

    function getRdvFormModel(): RdvForm {
        return {
            requestType: requestType,
            doctor: doctor,
            branche: branche,
            firstName: firstName,
            lastName: lastName,
            birthDate: birthDate,
            email: email,
            phone: phone,
            comment: comment,
            recaptchaValue: recaptchaToken,
            globalSettingsId: props.globalSettingsId,
            contactRecipientEmailAddress: props.contactRecipientEmailAddress,
        }
    }

    function setRequest(event: any, index: any) {
        setRequestType(event.target.value);
        setRequestIndex(index);
    }

    function handleSubmit(e: any) {
        e.preventDefault();
        resetErrorMessages();
        resetSuccesInfo();

        if (props.googleRecaptchaSiteKey && !recaptchaToken) {
            resetRecatpcha();
            setRecaptchaError(translate(dictionaryKeys.site.global.form.requiredField))
            return;
        }

        validationSchema.current.validate(getRdvFormModel(), { abortEarly: false })
            .then(() => 
            {
                executeApiRequest();
            })
            .catch((validationError: Yup.ValidationError) => {
                resetRecatpcha()
                manageErrors(validationError);
            });
    }

    function resetRecatpcha() {
        recaptchaRef.current && recaptchaRef.current.reset();
    }

    function manageErrors(validationError: Yup.ValidationError) {
        validationError.inner.forEach((error: Yup.ValidationError) => {
            switch (error.path) {
                case "requestType":
                    setRequestTypeError(error.message);
                    break;
                case "firstName":
                    setFirstNameError(error.message);
                    break;
                case "lastName":
                    setLastNameError(error.message);
                    break;
                case "birthDate":
                    setBirthDateError(error.message);
                    break;
                case "email":
                    setEmailError(error.message);
                    break;
                case "phone":
                    setPhoneError(error.message);
                    break;
            }
        });
    }

    function resetErrorMessages() {
        setRequestTypeError(undefined);
        setFirstNameError(undefined);
        setLastNameError(undefined);
        setBirthDateError(undefined);
        setPhoneError(undefined);
        setEmailError(undefined);
        setRecaptchaError(undefined);
    }

    function resetSuccesInfo() {
        setIsError(false);
        setIsSuccess(false);
        setStatusMessage(undefined);
    }

    function handleRecaptchaChange(recaptchaToken: string) {
        setRecaptchaToken(recaptchaToken);
    }

    return (
        <div className="form contact-form ajax-wrap">
            <div className={`inner ${props.formBgColorClass} ${props.formColorLabel}-text`}>
                <form onSubmit={handleSubmit}>
                    <div className="form-inner">
                        <div className="container">
                            <fieldset>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="doctor" className="control-label">{translate(dictionaryKeys.site.rdvForm.doctor)}</label>
                                        <input className="form-control" id="doctor" onChange={event => setDoctor(event.target.value)}></input>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="branche" className="control-label">{translate(dictionaryKeys.site.rdvForm.branche)}</label>
                                        <input className="form-control" id="branche" onChange={event => setBranche(event.target.value)}></input>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="firstName" className="control-label">{translate(dictionaryKeys.site.rdvForm.lastName)} <em className="req">*</em></label>
                                        <input type="text" className="form-control" id="firstName" onChange={event => setFirstName(event.target.value)} />
                                        {!!firstNameError &&
                                            <span className="field-validation-error">{firstNameError}</span>
                                        }
                                    </div>
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="lastName" className="control-label">{translate(dictionaryKeys.site.rdvForm.firstName)} <em className="req">*</em></label>
                                        <input type="text" className="form-control" id="lastName" onChange={event => setLastName(event.target.value)} />
                                        {!!lastNameError &&
                                            <span className="field-validation-error">{lastNameError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="birthDate" className="control-label">{translate(dictionaryKeys.site.rdvForm.birthDate)} <em className="req">*</em></label>
                                        <input type="date" placeholder="jj/mm/aaaa" className="form-control" id="birthDate" onChange={event => setBirthDate(event.target.value)} />
                                        {!!birthDateError &&
                                            <span className="field-validation-error">{birthDateError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="email" className="control-label">{translate(dictionaryKeys.site.rdvForm.email)}</label>
                                        <input type="email" autoCapitalize="off" className="form-control" id="email" onChange={event => setEmail(event.target.value)} />
                                        {!!emailError &&
                                            <span className="field-validation-error">{emailError}</span>
                                        }
                                    </div>
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="phone" className="control-label">{translate(dictionaryKeys.site.rdvForm.phone)} <em className="req">*</em></label>
                                        <input type="tel" className="form-control" id="phone" onChange={event => setPhone(event.target.value)} />
                                        {!!phoneError &&
                                            <span className="field-validation-error">{phoneError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="comment" className="control-label">{translate(dictionaryKeys.site.rdvForm.comment)}</label>
                                        <textarea className="form-control" id="comment" onChange={event => setComment(event.target.value)}></textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12"> 
                                        <label className="control-label">{translate(dictionaryKeys.site.rdvForm.requestType)} <em className="req">*</em></label>
                                        <div className="form-group radio">
                                        <div className="form-control">
                                            <div className="radioRow">
                                                    <input id="R" name="requestType" type="radio" value={translate(dictionaryKeys.site.rdvForm.requestTypeRequest)} onChange={event => setRequest(event,1)} />
                                                <label htmlFor="R">{translate(dictionaryKeys.site.rdvForm.requestTypeRequest)}</label>
                                            </div>
                                            <div className="radioRow">
                                                    <input id="C" name="requestType" type="radio" value={translate(dictionaryKeys.site.rdvForm.requestTypeCancel)} onChange={event => setRequest(event,0)} />
                                                <label htmlFor="C">{translate(dictionaryKeys.site.rdvForm.requestTypeCancel)}</label>
                                            </div>
                                        </div>
                                        </div>
                                        {!!requestTypeError &&
                                            <span className="field-validation-error">{requestTypeError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-5 col-xs-5">
                                        {!!(props.googleRecaptchaSiteKey && props.captchaDataSize) &&
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                size={props.captchaDataSize}
                                                sitekey={props.googleRecaptchaSiteKey}
                                                onChange={handleRecaptchaChange}
                                            />
                                        }
                                        {!!recaptchaError &&
                                            <span className="field-validation-error">{recaptchaError}</span>
                                        }
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="buttons">
                                <button role="button"
                                    className={`btn ${props.formButtonColorClass} ${props.formButtonHoverColorClass}
                                              ${props.formButtonColorLabel}-btn-text ${props.formButtonColorLabel}-btn-borders`}
                                    type="submit">
                                {translate(dictionaryKeys.site.global.form.submit)}
                                </button>
                            </fieldset>
                            {isSuccess &&
                                <div className="alert alert-success"><div className="info">{statusMessage}</div></div>
                            }
                            {isError &&
                                <div className="alert alert-warning"><div className="info">{statusMessage}</div></div>
                            }
                        </div>
                        
                    </div>
                </form>
            </div>
        </div>
    );
}

WidgetHelper.register(RdvFormWidget, 'rdv-form');