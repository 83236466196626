import React, { useState, useRef, useEffect, useCallback } from 'react';
import { translate, WidgetHelper } from '../Helpers';
import { Medecin } from '../Models';
import { dictionaryKeys } from '../Constants';
import Select, { createFilter, components, DropdownIndicatorProps } from 'react-select';
import { Config } from 'react-select/src/filters';



export function AutocompleteFormWidget() {

    const mounted = useRef(true);
    const [medecins, setMedecins] = useState<Medecin[]>();
    const [service, setService] = useState({ status: "init" });

    function getListeMedecins() {
        let options = medecins!.map((medecin: Medecin) => {
            return { value: medecin.id, label: medecin.description };
        });
        return options;
    }

    useEffect(() => {
        const formatedDataHeaders = new Headers();
        formatedDataHeaders.append("Accept-Language", document.querySelector("html")!.lang);
        formatedDataHeaders.append("Content-Type", "application/json");
        setService({ status: "Loading" });
        fetch(
            "/umbraco/api/autocomplete/getAllMedecin",
            {
                headers: formatedDataHeaders,
                method: "GET",
            })
            .then((resp) => {
                if (!resp.ok) {
                    throw Error(resp.statusText);
                }
                return resp.json()
            })
            .then((data) => {
                if (mounted.current) {
                    const cs = data as Medecin[];

                    setMedecins(cs);
                    setService({ status: (typeof cs === "undefined" || cs.length == 0 ? "Empty" : "Loaded") });
                }
            }).catch(function (error) {
                setService({ status: "Error" });
                console.log("Erreur : " + error);
            });
        return () => {
            mounted.current = false;
        }
    }, [])

    const handleSelect = useCallback((selectedOption) => {
        
        window.open(translate(dictionaryKeys.site.routing.consultations) + "?key=" + selectedOption.value);

    }, []);

    const [showOptions, setShowOptions] = useState(false);

    const handleInputChange = useCallback((typedOption) => {
        if (typedOption.length > 2) {
            setShowOptions(true);
        }
        else {
            setShowOptions(false);
        }
    }, []);

    const filterConfig: Config = {
        matchFrom: "any",
        ignoreAccents: true,
        ignoreCase: true,
        trim: true
    }

    const DropdownIndicator = (
        props: DropdownIndicatorProps<string, true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <i className="icon usn_ion-ios-search"></i>
            </components.DropdownIndicator>
        );
    };

    return (
        <div className="row">
            <div className="form-group col-md-4 col-xs-12"></div>
            <div className="form-group col-md-4 col-xs-12">
                {service.status === "Loaded" &&
                    <Select
                        placeholder={translate(dictionaryKeys.site.global.form.search)}xw
                        openMenuOnFocus={false}
                        openMenuOnClick={false}
                        name="medecin"
                        value=""
                        onInputChange={handleInputChange}
                        classNamePrefix="select"
                        options={showOptions ? getListeMedecins() : []}
                        onChange={handleSelect}
                        noOptionsMessage={() => translate(dictionaryKeys.site.global.form.emptyData)}
                        components={{
                            DropdownIndicator: DropdownIndicator,
                            
                        }}
                        filterOption={createFilter(filterConfig)}
                        styles={{
                            option: (base) => ({
                                ...base,
                                '&:hover': { backgroundColor: '#8CC63F' },
                                backgroundColor: 'white',
                            }),
                            control: (base, state) => ({
                                ...base,
                                '&:hover': { borderColor: '#8CC63F' }, 
                                border: state.isFocused ? '2px solid #8CC63F' : '2px solid lightgrey', 
                                boxShadow: 'none', 
                            }),
                        }}
                    />
                }
            </div>
            <div className="form-group col-md-4 col-xs-12"></div>
        </div>
    );
};



WidgetHelper.register(AutocompleteFormWidget, 'autocomplete-form');