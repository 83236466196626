import { RecaptchaForm } from './RecaptchaForm';

export interface Child {
    gender: string, firstName: string, lastName: string, birthDate: string, allergy: string
}
export interface PeriodPlace {
    when: string, where: string
}
export interface Config {
    label: string, lieux: string[];
}
export interface StageAesForm extends RecaptchaForm {
    period?: PeriodPlace[];
    civility?: string;
    authorized?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    address?: string;
    nursery?: string;
    xxlFamily?: string;
    isIsppc?: string;
    zipCode?: string;
    town?: string;
    comment?: string;
    children?: Child[];
    contactRecipientEmailAddress?: string;
}