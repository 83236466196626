import React, { useState, useRef, useEffect } from 'react';
import { translate, WidgetHelper } from '../Helpers';
import { ConsultService, SiteSession, Session, Periode, PrestatairePeriode, Day } from '../Models';
import { dictionaryKeys } from '../Constants';

interface ConsultProps {
    formBgColorClass: string;
    formColorLabel: string;
    formButtonColorLabel: string;
    formButtonColorClass: string;
    formButtonHoverColorClass: string;
    globalSettingsId: string;
    service: number;
    showConv: string
    showFonc: string;
}

export function ConsultWidget(props: ConsultProps) {

    const [info, setInfo] = useState<string>();
    const [consult, setConsult] = useState<ConsultService>();
    const [service, setService] = useState({ status: "init" });
    const week: Day[] = [{ day: translate(dictionaryKeys.site.global.form.days.monday), abrev: translate(dictionaryKeys.site.global.form.days.abreviation.monday)},
    { day: translate(dictionaryKeys.site.global.form.days.tuesday), abrev: translate(dictionaryKeys.site.global.form.days.abreviation.tuesday)},
    { day: translate(dictionaryKeys.site.global.form.days.wednesday), abrev: translate(dictionaryKeys.site.global.form.days.abreviation.wednesday)},
    { day: translate(dictionaryKeys.site.global.form.days.thursday), abrev: translate(dictionaryKeys.site.global.form.days.abreviation.thursday)},
    { day: translate(dictionaryKeys.site.global.form.days.friday), abrev: translate(dictionaryKeys.site.global.form.days.abreviation.friday)},
    { day: translate(dictionaryKeys.site.global.form.days.saturday), abrev: translate(dictionaryKeys.site.global.form.days.abreviation.saturday)},
    { day: translate(dictionaryKeys.site.global.form.days.sunday), abrev: translate(dictionaryKeys.site.global.form.days.abreviation.sunday)}];
    const mounted = useRef(true);

    function getDistinctPrestataireBySession(sessions: Session[]) {
        const prestataires: PrestatairePeriode[] = [];
        const ids: number[] = [];
        sessions.map((session: Session) => {
            if (ids.indexOf(session.prestataire.id) === -1) {
                ids.push(session.prestataire.id);
                prestataires.push({ prestataire: session.prestataire, periodes: [session.periode] });
            } else {
                prestataires[ids.indexOf(session.prestataire.id)].periodes.push(session.periode);
            }
        });

        return prestataires;
    }
    function isActiveSession(periodes: Periode[], jour: number, tranche: number) {
        return (periodes.filter(periode => periode.jour === jour+1 && (periode.tranche === tranche || periode.tranche === 3)).length > 0);
    }

    function isConventionne(siteSession: SiteSession, id: number, jour: number) {
        if (props.showConv === "False") return true;
        const sess:Session = siteSession.sessions.filter(session => session.prestataire.id === id && session.periode.jour === jour+1)[0];
        if (typeof sess === "undefined") return false;
        return sess.prestataire.isConventionne;
    }

    var querySelectorHas = function (parent: string, child: string): HTMLInputElement[] {
        return [].filter.call(document.querySelectorAll(parent), function (elem:Element) {
            return elem.querySelector(child)
        });
    }

    useEffect(() => {
        const formatedDataHeaders = new Headers();
        formatedDataHeaders.append("Accept-Language", document.querySelector("html")!.lang);
        formatedDataHeaders.append("Content-Type", "application/json");
        setService({ status: "Loading" })
        fetch(
            "/umbraco/api/consult/getConsultByService?service=" + props.service,
            {
                headers: formatedDataHeaders,
                method: "POST",
            })
            .then((resp) => {
                if (!resp.ok) {
                    throw Error(resp.statusText);
                }
                return resp.json()
            })
            .then((data) => {
                
                if (mounted.current) {
                    const cs = data[0] as ConsultService;
                    cs.sitesSessions.sort((a, b) => b.sessions.length - a.sessions.length)
                    setConsult(cs);
                    console.log(props.showConv === "True")
                   
                    setService({ status: (typeof cs === "undefined" ? "Empty" : "Loaded") });
                    querySelectorHas('#tbl-consult tbody td.cell-day', 'span.badge-am').forEach(elem => {
                        elem.style.verticalAlign = "top";
                    });
                    querySelectorHas('#tbl-consult tbody td.cell-day', 'span.badge-pm').forEach(elem => {
                        elem.style.verticalAlign = elem.style.verticalAlign == "top" ? "" : "bottom";
                    });
                }
            }).catch(function (error) {
                console.error(error);
                setService({ status: "Error" });
                setInfo(error.message);
            });
        return () => {
            mounted.current = false;

        }
    }, [])

    return (
        <div className="form consult-form ajax-wrap">
            <div className={`inner ${props.formBgColorClass} ${props.formColorLabel}-text`}>

                <div className="form-inner">
                    <div className="container">
                        <div className="info col">
                            <p className="heading main base-heading">{translate(dictionaryKeys.site.consultationForm.consultation)}&nbsp;
                                {service.status === "Loaded" &&
                                    <span>{consult!.descriptionDisciplineMedicale.toLowerCase()}</span>
                                }
                            </p>
                        </div>
                        <fieldset>
                            <div className="row">
                                <div className="form-group col-12">
                                    {service.status === "Loading" &&
                                        <div className="alert alert-info"><div className="info">{translate(dictionaryKeys.site.global.form.loading)}</div></div>
                                    }
                                    {service.status === "Error" &&
                                        <div className="alert alert-warning"><div className="info">{translate(dictionaryKeys.site.global.form.errorOccured)} {info}</div></div>
                                    }
                                    {service.status === "Empty" &&
                                        <div className="alert alert-info"><div className="info">{translate(dictionaryKeys.site.global.form.emptyData)}</div></div>
                                    }
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-12">
                                    <div className="accordion" id="acdConsult">
                                        {service.status === "Loaded" &&
                                            consult!.sitesSessions.map((site: SiteSession, idx: number) => (
                                                <div className="card" key={"d" + site.id}>
                                                    <div className="card-header" id={"s" + site.id.toString()}>
                                                        <a className="card-link collapsed" style={{ display: "flex", justifyContent: "space-between" }} data-toggle="collapse" href={"#c" + site.id.toString()} >
                                                            <span className="control-label base-link">{site.description}</span>
                                                            <span className="control-label base-link">{site.telecoms.length>0 && site.telecoms.map(item => item.description).reduce((prev, curr) => prev + ', ' + curr)}</span>
                                                        </a>
                                                    </div>
                                                    <div id={"c" + site.id.toString()} className={"collapse " + (idx === 0 ? "show" : "")} data-parent="#acdConsult">
                                                        <div className="card-body">
                                                            <table id="tbl-consult">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">{translate(dictionaryKeys.site.consultationForm.doctor)}</th>
                                                                        {week.map((d: Day, i:number) =>
                                                                            <th scope="col" className="cell-day" key={"j" + i}><span title={d.day}>{d.abrev}</span></th>
                                                                        )}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {getDistinctPrestataireBySession(site.sessions).map((pp: PrestatairePeriode) => (
                                                                        <tr key={"p" + pp.prestataire.id}>
                                                                            <td>
                                                                                <div><a href={"../consultations?key=" + pp.prestataire.id}>{pp.prestataire.description}</a></div>
                                                                                {(props.showFonc === "True") &&
                                                                                    < em > { pp.prestataire.fonction }</em>
                                                                                }
                                                                            </td>
                                                                            {week.map((d: Day, i:number) =>
                                                                                <td className="cell-day" aria-label={d.day} key={"s" + i}>
                                                                                    {isActiveSession(pp.periodes, i, 1) &&
                                                                                        <span className={"badge badge-am " + (isConventionne(site, pp.prestataire.id, i) ? "badge-green" : "badge-orange")} title={isConventionne(site, pp.prestataire.id, i) ? translate(dictionaryKeys.site.consultationForm.conventionne) : translate(dictionaryKeys.site.consultationForm.nonConventionne)}>{translate(dictionaryKeys.site.global.form.dayMorning)}</span>
                                                                                    }
                                                                                    {isActiveSession(pp.periodes, i, 2) &&
                                                                                        <span className={"badge badge-pm " + (isConventionne(site, pp.prestataire.id, i) ? "badge-green" : "badge-orange")} title={isConventionne(site, pp.prestataire.id, i) ? translate(dictionaryKeys.site.consultationForm.conventionne) : translate(dictionaryKeys.site.consultationForm.nonConventionne)}>{translate(dictionaryKeys.site.global.form.dayAfternoon)}</span>
                                                                                    }
                                                                                </td>
                                                                            )}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                                {(props.showConv === "True") &&
                                                                <tfoot>
                                                                    <tr>
                                                                        <td colSpan={week.length + 1}>
                                                                                <div>
                                                                                    <span className="badge badge-green">{translate(dictionaryKeys.site.consultationForm.conventionne)}</span>
                                                                                    &nbsp;<span className="badge badge-orange">{translate(dictionaryKeys.site.consultationForm.nonConventionne)}</span>
                                                                                </div>
                                                                        </td>
                                                                    </tr>
                                                                </tfoot>
                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>

            </div>
        </div>
    );
}

WidgetHelper.register(ConsultWidget, 'consult-form');