import React, { RefObject, useRef, useState } from 'react';
import { translate, WidgetHelper } from '../Helpers';
import * as Yup from 'yup';
import { StageAesForm, Child, PeriodPlace, Config } from '../Models';
import ReCAPTCHA from 'react-google-recaptcha';
import { dictionaryKeys, constants } from '../Constants';

interface StageAesFormProps {
    formBgColorClass: string;
    formColorLabel: string;
    formButtonColorLabel: string;
    formButtonColorClass: string;
    formButtonHoverColorClass: string;
    googleRecaptchaSiteKey: string;
    captchaDataSize: "compact" | "normal" | "invisible" | undefined;
    globalSettingsId: string;
    contactRecipientEmailAddress: string;
    formPeriodeOptions: Config[];
}

export function StageAesFormWidget(props: StageAesFormProps) {
    const recaptchaRef: RefObject<ReCAPTCHA> = React.createRef();
    const majorityDate = new Date();
    majorityDate.setFullYear(majorityDate.getFullYear() - constants.site.aesForm.children.majority)

    const validationSchema = useRef(
        Yup.object({
            children: Yup.array().of(
                Yup.object().shape({
                    gender: Yup.string().required(translate(dictionaryKeys.site.global.form.requiredField)),
                    firstName: Yup.string().required(translate(dictionaryKeys.site.global.form.requiredField)),
                    lastName: Yup.string().required(translate(dictionaryKeys.site.global.form.requiredField)),
                    birthDate: Yup.date().required(translate(dictionaryKeys.site.global.form.requiredField))
                        .max(new Date(), translate(dictionaryKeys.site.global.form.date.lessThanDay))
                        .min(majorityDate, translate(dictionaryKeys.site.global.form.date.greaterThan) + majorityDate.toLocaleDateString()),
                    allergy: Yup.string().notRequired(),
                }))
                .min(constants.site.aesForm.children.min, translate(dictionaryKeys.site.stageAesForm.childrenCountError)),
            period: Yup.array().of(
                Yup.object().shape({
                    where: Yup.string().required(translate(dictionaryKeys.site.global.form.requiredField)),
                    when: Yup.string().required(translate(dictionaryKeys.site.global.form.requiredField)),
                }))
                .min(constants.site.aesForm.periods.min, translate(dictionaryKeys.site.global.form.requiredField)),
            nursery: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            xxlFamily: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            isIsppc: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            civility: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            firstName: Yup.string()
                .trim()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            lastName: Yup.string()
                .trim()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            address: Yup.string()
                .trim()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            town: Yup.string()
                .trim()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            zipCode: Yup.string()
                .trim()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            email: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField))
                .email(translate(dictionaryKeys.site.global.form.invalidEmail)),
            authorized: Yup.string().notRequired(),
            comment: Yup.string().notRequired(),
            phone: Yup.string()
                .trim()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
        })
    );

    const [period, setPeriod] = useState<PeriodPlace[]>([]);
    const [civility, setCivility] = useState<string>();
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [authorized, setAuthorized] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [phone, setPhone] = useState<string>();
    const [address, setAddress] = useState<string>();
    const [zipCode, setZipCode] = useState<string>();
    const [town, setTown] = useState<string>();
    const [nursery, setNursery] = useState<string>();
    const [xxlFamily, setXxlFamily] = useState<string>();
    const [isIsppc, setIsIsppc] = useState<string>();
    const [comment, setComment] = useState<string>();
    const [children, setChildren] = useState<Child[]>([]);

    const [childFirstName, setChildFirstName] = useState<string>();
    const [childLastName, setChildLastName] = useState<string>();
    const [childBirthDate, setChildBirthDate] = useState<string>();
    const [childAllergy, setChildAllergy] = useState<string>();
    const [childGender, setChildGender] = useState<string>();

    const [periodError, setPeriodError] = useState<string>();
    const [isIsppcError, setIsIsppcError] = useState<string>();
    const [xxlFamilyError, setXxlFamilyError] = useState<string>();
    const [nurseryError, setNurseryError] = useState<string>();
    const [civilityError, setCivilityError] = useState<string>();
    const [addressError, setAddressError] = useState<string>();
    const [zipCodeError, setZipCodeError] = useState<string>();
    const [townError, setTownError] = useState<string>();
    const [firstNameError, setFirstNameError] = useState<string>();
    const [lastNameError, setLastNameError] = useState<string>();
    const [emailError, setEmailError] = useState<string>();
    const [phoneError, setPhoneError] = useState<string>();
    const [childrenError, setChildrenError] = useState<string>();

    const [recaptchaError, setRecaptchaError] = useState<string>();
    const [recaptchaToken, setRecaptchaToken] = useState<string>();
    const [statusMessage, setStatusMessage] = useState<string>();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    const isNullOrEmpty = (s?: string) => {
        if (typeof (s) !== 'undefined' && s != null) {
            if (s.trim() !== '') return false;
            return true;
        }
        return true;
    };

    function executeApiRequest() {
        const formatedDataHeaders = new Headers();
        formatedDataHeaders.append("Accept-Language", document.querySelector("html")!.lang);
        formatedDataHeaders.append("Content-Type", "application/json");
        fetch(
            "/umbraco/api/contact/SendStageAesRequest",
            {
                headers: formatedDataHeaders,
                method: "post",
                body: JSON.stringify(getStageAesFormModel()),
            })
            .then((response) => {
                if (response.ok) {
                    setStatusMessage(translate(dictionaryKeys.site.global.form.successMessage));
                    setIsSuccess(true);
                    setIsError(false);
                }
                else {
                    setStatusMessage(translate(dictionaryKeys.site.global.form.errorOccured));
                    setIsSuccess(false);
                    setIsError(true);
                }
            });
    }

    function getStageAesFormModel(): StageAesForm {
        return {
            period: period,
            civility: civility,
            firstName: firstName,
            lastName: lastName,
            authorized: authorized,
            email: email,
            phone: phone,
            address: address,
            nursery: nursery,
            xxlFamily: xxlFamily,
            isIsppc: isIsppc,
            zipCode: zipCode,
            town: town,
            comment: comment,
            children: children,
            recaptchaValue: recaptchaToken,
            globalSettingsId: props.globalSettingsId,
            contactRecipientEmailAddress: props.contactRecipientEmailAddress,
        }
    }

    function handleSubmit(e: any) {

        e.preventDefault();
        resetErrorMessages();
        resetSuccesInfo();

        if (props.googleRecaptchaSiteKey && !recaptchaToken) {
            resetRecatpcha();
            setRecaptchaError(translate(dictionaryKeys.site.global.form.requiredField))
            return;
        }

        validationSchema.current.validate(getStageAesFormModel(), { abortEarly: false })
            .then(() => {
                executeApiRequest();
            })
            .catch((validationError: Yup.ValidationError) => {
                resetRecatpcha()
                manageErrors(validationError);
            });
    }

    function resetRecatpcha() {
        recaptchaRef.current && recaptchaRef.current.reset();
    }

    function manageErrors(validationError: Yup.ValidationError) {

        validationError.inner.forEach((error: Yup.ValidationError) => {
            if (error.path!.startsWith("children")) {
                setChildrenError(error.message);
            } else {
                switch (error.path) {
                    case "period":
                        setPeriodError(error.message);
                        break;
                    case "civility":
                        setCivilityError(error.message);
                        break;
                    case "firstName":
                        setFirstNameError(error.message);
                        break;
                    case "lastName":
                        setLastNameError(error.message);
                        break;
                    case "address":
                        setAddress(error.message);
                        break;
                    case "zipCode":
                        setZipCodeError(error.message);
                        break;
                    case "town":
                        setTownError(error.message);
                        break;
                    case "email":
                        setEmailError(error.message);
                        break;
                    case "phone":
                        setPhoneError(error.message);
                        break;
                    case "nursery":
                        setNurseryError(error.message);
                        break;
                    case "xxlFamily":
                        setXxlFamilyError(error.message);
                        break;
                    case "isIsppc":
                        setIsIsppcError(error.message);
                        break;
                }
            }
        });
    }

    function resetErrorMessages() {
        setPeriodError(undefined);
        setCivilityError(undefined);
        setFirstNameError(undefined);
        setLastNameError(undefined);
        setAddressError(undefined);
        setZipCodeError(undefined);
        setTownError(undefined);
        setEmailError(undefined);
        setIsIsppcError(undefined);
        setXxlFamilyError(undefined);
        setNurseryError(undefined);
        setAddressError(undefined);
        setPhoneError(undefined);
        setChildrenError(undefined);
        setRecaptchaError(undefined);
    }

    function resetSuccesInfo() {
        setIsError(false);
        setIsSuccess(false);
        setStatusMessage(undefined);
    }

    function handleRecaptchaChange(recaptchaToken: string) {
        setRecaptchaToken(recaptchaToken);
    }

    function place_Change(where: string, when: string) {
   
        let filteredArray = period.filter(item => item.when == when)
        if (filteredArray.length > 0) { // if exist : remove+add
            filteredArray = period.filter(item => item.when !== when).concat({ where: where, when: when });
            setPeriod(filteredArray.filter(item => item.where !== ""));
        } else {
            setPeriod(period => period.concat({ where: where, when: when }));
        }
        
    }

    function addChild_Click() {
        if (isNullOrEmpty(childGender) || isNullOrEmpty(childFirstName) || isNullOrEmpty(childLastName) || isNullOrEmpty(childBirthDate)) {
            setChildrenError(translate(dictionaryKeys.site.global.form.requiredField));
        } else {
            setChildrenError(undefined);
            setChildren(children => children.concat({ gender: childGender!, firstName: childFirstName!.trim(), lastName: childLastName!.trim(), birthDate: childBirthDate!, allergy: childAllergy ? childAllergy.trim() : "" }))
            setChildFirstName("");
            setChildLastName("");
            setChildBirthDate("");
            setChildAllergy("");
            //setChildren(children => [children.filter(item => !(item && Object.keys(item).length === 0)), { gender: childGender, firstName: childFirstName, lastName: childLastName, birthDate: childBirthDate, allergy: childAllergy }]);
        }
    }

    function removeChild_Click(o: Child) {
        let filteredArray = children.filter(item => item !== o)
        setChildren(filteredArray);
    }

    return (
        <div className="form stageaes-form ajax-wrap">
            <div className={`inner ${props.formBgColorClass} ${props.formColorLabel}-text`}>
                <form onSubmit={handleSubmit}>
                    <div className="form-inner">
                        <div className="container">
                            <fieldset>
                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <span className="base-link">{translate(dictionaryKeys.site.stageAesForm.title)}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="lastName" className="control-label">{translate(dictionaryKeys.site.stageAesForm.lastName)} <em className="req">*</em></label>
                                        <input type="text" className="form-control" id="lastName" onChange={event => setLastName(event.target.value)} />
                                        {!!lastNameError &&
                                            <span className="field-validation-error">{lastNameError}</span>
                                        }
                                    </div>
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="firstName" className="control-label">{translate(dictionaryKeys.site.stageAesForm.firstName)} <em className="req">*</em></label>
                                        <input type="text" className="form-control" id="firstName" onChange={event => setFirstName(event.target.value)} />
                                        {!!firstNameError &&
                                            <span className="field-validation-error">{firstNameError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="form-group col-12">
                                        <label className="control-label">{translate(dictionaryKeys.site.stageAesForm.civility)} <em className="req">*</em></label>
                                        <div className="form-group radio">
                                            <div className="form-control">
                                                <div className="radioRow">
                                                    <input id="W" name="civility" type="radio" value={translate(dictionaryKeys.site.global.form.civilityWoman)} onChange={event => setCivility(event.target.value)} />
                                                    <label htmlFor="W">{translate(dictionaryKeys.site.global.form.civilityWoman)}</label>
                                                </div>
                                                <div className="radioRow">
                                                    <input id="M" name="civility" type="radio" value={translate(dictionaryKeys.site.global.form.civilityMan)} onChange={event => setCivility(event.target.value)} />
                                                    <label htmlFor="M">{translate(dictionaryKeys.site.global.form.civilityMan)}</label>
                                                </div>
                                            </div></div>
                                        {!!civilityError &&
                                            <span className="field-validation-error">{civilityError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="address" className="control-label">{translate(dictionaryKeys.site.stageAesForm.address)} <em className="req">*</em></label>
                                        <textarea className="form-control" id="address" onChange={event => setAddress(event.target.value)} />
                                        {!!addressError &&
                                            <span className="field-validation-error">{addressError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="zipCode" className="control-label">{translate(dictionaryKeys.site.stageAesForm.zipCode)} <em className="req">*</em></label>
                                        <input type="text" className="form-control" id="zipCode" onChange={event => setZipCode(event.target.value)} />
                                        {!!zipCodeError &&
                                            <span className="field-validation-error">{zipCodeError}</span>
                                        }
                                    </div>
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="town" className="control-label">{translate(dictionaryKeys.site.stageAesForm.town)} <em className="req">*</em></label>
                                        <input type="text" className="form-control" id="town" onChange={event => setTown(event.target.value)} />
                                        {!!townError &&
                                            <span className="field-validation-error">{townError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="email" className="control-label">{translate(dictionaryKeys.site.stageAesForm.email)} <em className="req">*</em></label>
                                        <input type="email" autoCapitalize="off" className="form-control" id="email" onChange={event => setEmail(event.target.value)} />
                                        {!!emailError &&
                                            <span className="field-validation-error">{emailError}</span>
                                        }
                                    </div>
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="phone" className="control-label">{translate(dictionaryKeys.site.stageAesForm.phone)} <em className="req">*</em></label>
                                        <input type="tel" className="form-control" id="phone" onChange={event => setPhone(event.target.value)} />
                                        {!!phoneError &&
                                            <span className="field-validation-error">{phoneError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="authorized" className="control-label">{translate(dictionaryKeys.site.stageAesForm.authorized)}</label>
                                        <textarea className="form-control" id="authorized" onChange={event => setAuthorized(event.target.value)} />
                                        <em className="req">{translate(dictionaryKeys.site.stageAesForm.authorizedInfo)}</em>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="comment" className="control-label">{translate(dictionaryKeys.site.stageAesForm.comment)}</label>
                                        <textarea className="form-control" id="comment" onChange={event => setComment(event.target.value)} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-4">
                                        <label className="control-label">{translate(dictionaryKeys.site.stageAesForm.nursery)} <em className="req">*</em></label>
                                        <div className="form-group radio">
                                            <div className="form-control">
                                                <div className="radioRow">
                                                    <input id="ny" name="nursery" type="radio" value={translate(dictionaryKeys.site.global.form.yes)} onChange={event => setNursery(event.target.value)} />
                                                    <label htmlFor="ny">{translate(dictionaryKeys.site.global.form.yes)}</label>
                                                </div>
                                                <div className="radioRow">
                                                    <input id="nn" name="nursery" type="radio" value={translate(dictionaryKeys.site.global.form.no)} onChange={event => setNursery(event.target.value)} />
                                                    <label htmlFor="nn">{translate(dictionaryKeys.site.global.form.no)}</label>
                                                </div>
                                            </div></div>
                                        {!!nurseryError &&
                                            <span className="field-validation-error">{nurseryError}</span>
                                        }
                                    </div>

                                    <div className="form-group col-4">
                                        <label className="control-label">{translate(dictionaryKeys.site.stageAesForm.xxlFamily)} <em className="req">*</em></label>
                                        <div className="form-group radio">
                                            <div className="form-control">
                                                <div className="radioRow">
                                                    <input id="xy" name="xxlFamily" type="radio" value={translate(dictionaryKeys.site.global.form.yes)} onChange={event => setXxlFamily(event.target.value)} />
                                                    <label htmlFor="xy">{translate(dictionaryKeys.site.global.form.yes)}</label>
                                                </div>
                                                <div className="radioRow">
                                                    <input id="xn" name="xxlFamily" type="radio" value={translate(dictionaryKeys.site.global.form.no)} onChange={event => setXxlFamily(event.target.value)} />
                                                    <label htmlFor="xn">{translate(dictionaryKeys.site.global.form.no)}</label>
                                                </div>
                                            </div></div>
                                        {!!xxlFamilyError &&
                                            <span className="field-validation-error">{xxlFamilyError}</span>
                                        }
                                    </div>

                                    <div className="form-group col-4">
                                        <label className="control-label">{translate(dictionaryKeys.site.stageAesForm.isIsppc)} <em className="req">*</em></label>
                                        <div className="form-group radio">
                                            <div className="form-control">
                                                <div className="radioRow">
                                                    <input id="iy" name="isIsppc" type="radio" value={translate(dictionaryKeys.site.global.form.yes)} onChange={event => setIsIsppc(event.target.value)} />
                                                    <label htmlFor="iy">{translate(dictionaryKeys.site.global.form.yes)}</label>
                                                </div>
                                                <div className="radioRow">
                                                    <input id="in" name="isIsppc" type="radio" value={translate(dictionaryKeys.site.global.form.no)} onChange={event => setIsIsppc(event.target.value)} />
                                                    <label htmlFor="in">{translate(dictionaryKeys.site.global.form.no)}</label>
                                                </div>
                                            </div></div>
                                        {!!isIsppcError &&
                                            <span className="field-validation-error">{isIsppcError}</span>
                                        }
                                    </div>
                                </div>
                                {props.formPeriodeOptions.map((val: Config, idx) => (
                                    <div className="row" key={"pp" + idx}>
                                        <div className="form-group col-12">
                                            <label className="control-label base-link">{val.label} <em className="req">*</em></label>
                                            <select className="form-control" onChange={event => place_Change(event.target.value, val.label)}>
                                                <option value=""></option>
                                                {val.lieux.map((v, i) => <option key={i} value={v}>{v}</option>)}
                                            </select>
                                            {!!periodError &&
                                                <span className="field-validation-error">{periodError}</span>
                                            }
                                        </div>
                                    </div>
                                ))}
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="control-label base-link">{translate(dictionaryKeys.site.stageAesForm.children)} <em className="req">*</em></label>
                                    </div> </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <table className="mb-0">
                                            <thead>
                                                <tr className="text-center">
                                                    <th scope="col">{translate(dictionaryKeys.site.stageAesForm.childrenGender)}</th>
                                                    <th scope="col">{translate(dictionaryKeys.site.stageAesForm.childrenLastName) + " " + translate(dictionaryKeys.site.stageAesForm.childrenFirstName)}</th>
                                                    <th scope="col">{translate(dictionaryKeys.site.stageAesForm.childrenBirthDate)}</th>
                                                    <th scope="col">{translate(dictionaryKeys.site.stageAesForm.childrenAllergy)}</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {children.map((val: Child, idx) => (
                                                    <tr key={"cc" + idx}>
                                                        <td aria-label={translate(dictionaryKeys.site.stageAesForm.childrenGender)}>
                                                            <div className="radio radioRow">
                                                                <input id={"g" + idx} name={"childGender" + idx} type="radio" readOnly={true} checked={val.gender === translate(dictionaryKeys.site.global.form.civilityGirl)} value={translate(dictionaryKeys.site.global.form.civilityGirl)} />
                                                                <label htmlFor={"g" + idx}>{translate(dictionaryKeys.site.global.form.civilityGirl)}</label>
                                                            </div>
                                                            <div className="radio radioRow">
                                                                <input id={"b" + idx} name={"childGender" + idx} type="radio" readOnly={true} checked={val.gender === translate(dictionaryKeys.site.global.form.civilityBoy)} value={translate(dictionaryKeys.site.global.form.civilityBoy)} />
                                                                <label htmlFor={"b" + idx}>{translate(dictionaryKeys.site.global.form.civilityBoy)}</label>
                                                            </div>
                                                        </td>
                                                        <td aria-label={translate(dictionaryKeys.site.stageAesForm.childrenLastName) + " " + translate(dictionaryKeys.site.stageAesForm.childrenFirstName)}>
                                                            <input type="text" className="form-control" readOnly={true} value={val.lastName} />
                                                            <input type="text" className="form-control" readOnly={true} value={val.firstName} />
                                                        </td>
                                                        <td aria-label={translate(dictionaryKeys.site.stageAesForm.childrenBirthDate)}>
                                                            <input type="date" placeholder="jj/mm/aaaa" value={val.birthDate} className="form-control" readOnly={true} />
                                                        </td>
                                                        <td aria-label={translate(dictionaryKeys.site.stageAesForm.childrenAllergy)}>
                                                            <textarea className="form-control" key={"a" + idx} readOnly={true} value={val.allergy} />
                                                        </td>
                                                        <td>
                                                            <button role="button" onClick={() => removeChild_Click(val)}
                                                                className={`btn btn-danger 
                                                                ${props.formButtonColorLabel}-btn-text`}
                                                                type="button">
                                                                {translate(dictionaryKeys.site.global.form.delete)}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td aria-label={translate(dictionaryKeys.site.stageAesForm.childrenGender)}>
                                                        <div>
                                                        <div className="radio radioRow">
                                                            <input id="cg" name="childGender" type="radio" value={translate(dictionaryKeys.site.global.form.civilityGirl)} onChange={event => setChildGender(event.target.value)} />
                                                            <label htmlFor="cg">{translate(dictionaryKeys.site.global.form.civilityGirl)}</label>
                                                        </div>
                                                        <div className="radio radioRow">
                                                            <input id="cb" name="childGender" type="radio" value={translate(dictionaryKeys.site.global.form.civilityBoy)} onChange={event => setChildGender(event.target.value)} />
                                                            <label htmlFor="cb">{translate(dictionaryKeys.site.global.form.civilityBoy)}</label>
                                                            </div>
                                                            </div>
                                                    </td>
                                                    <td aria-label={translate(dictionaryKeys.site.stageAesForm.childrenLastName) + " " + translate(dictionaryKeys.site.stageAesForm.childrenFirstName)}>
                                                        <input type="text" className="form-control" readOnly={false} value={childLastName} placeholder={translate(dictionaryKeys.site.stageAesForm.childrenLastName)} onChange={event => setChildLastName(event.target.value)} />
                                                        <input type="text" className="form-control" readOnly={false} value={childFirstName} placeholder={translate(dictionaryKeys.site.stageAesForm.childrenFirstName)} onChange={event => setChildFirstName(event.target.value)} />
                                                    </td>
                                                    <td aria-label={translate(dictionaryKeys.site.stageAesForm.childrenBirthDate)}>
                                                        <input type="date" placeholder="jj/mm/aaaa" readOnly={false} value={childBirthDate} className="form-control" onChange={event => setChildBirthDate(event.target.value)} />
                                                    </td>
                                                    <td aria-label={translate(dictionaryKeys.site.stageAesForm.childrenAllergy)}>
                                                        <textarea className="form-control" readOnly={false} value={childAllergy} placeholder={translate(dictionaryKeys.site.stageAesForm.childrenAllergy_ph)} onChange={event => setChildAllergy(event.target.value)} />
                                                    </td>
                                                    <td className="text-center">
                                                        <button role="button" onClick={addChild_Click}
                                                            className={`btn ${props.formButtonColorClass} ${props.formButtonHoverColorClass}
                                                            ${props.formButtonColorLabel}-btn-text ${props.formButtonColorLabel}-btn-borders`}
                                                            type="button">
                                                            {translate(dictionaryKeys.site.global.form.add)}
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {!!childrenError &&
                                            <span className="field-validation-error">{childrenError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-5 col-xs-5">
                                        {!!(props.googleRecaptchaSiteKey && props.captchaDataSize) &&
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                size={props.captchaDataSize}
                                                sitekey={props.googleRecaptchaSiteKey}
                                                onChange={handleRecaptchaChange}
                                            />
                                        }
                                        {!!recaptchaError &&
                                            <span className="field-validation-error">{recaptchaError}</span>
                                        }
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="buttons">
                                <button role="button"
                                    className={`btn ${props.formButtonColorClass} ${props.formButtonHoverColorClass}
                                              ${props.formButtonColorLabel}-btn-text ${props.formButtonColorLabel}-btn-borders`}
                                    type="submit">
                                    {translate(dictionaryKeys.site.global.form.submit)}
                                </button>
                            </fieldset>
                            {isSuccess &&
                                <div className="alert alert-success"><div className="info">{statusMessage}</div></div>
                            }
                            {isError &&
                                <div className="alert alert-warning"><div className="info">{statusMessage}</div></div>
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

WidgetHelper.register(StageAesFormWidget, 'aes-form');