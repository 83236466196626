import React, { useState, useRef, useEffect } from 'react';
import { translate, WidgetHelper } from '../Helpers';
import { RoadTable } from '../Models';
import { dictionaryKeys } from '../Constants';
import DataTable from 'datatables.net-dt';
import languageFr from 'datatables.net-plugins/i18n/fr-FR.mjs';

interface RoadTableProps {
    formBgColorClass: string;
    formColorLabel: string;
    formButtonColorLabel: string;
    formButtonColorClass: string;
    formButtonHoverColorClass: string;
    globalSettingsId: string;
    site: string;
}

export function RoadTableWidget(props: RoadTableProps) {


    const [info, setInfo] = useState<string>();
    const [road, setRoad] = useState<RoadTable[]>();
    const [service, setService] = useState({ status: "init" });
    
    const mounted = useRef(true);

    useEffect(() => {
        const formatedDataHeaders = new Headers();
        formatedDataHeaders.append("Accept-Language", document.querySelector("html")!.lang);
        formatedDataHeaders.append("Content-Type", "application/json");
        setService({ status: "Loading" })
        fetch(
            "/umbraco/api/Road/getRoads?site=" + props.site,
            {
                headers: formatedDataHeaders,
                method: "POST",

            })
            .then((resp) => {
                if (!resp.ok) {
                    throw Error(resp.statusText);
                }
                return resp.json()
            })
            .then((data) => {
                if (mounted.current) {
                    const pm = data as RoadTable[];
                    setRoad(pm);
                    setService({ status: (typeof pm === "undefined" ? "Empty" : "Loaded") });
                    let table = new DataTable('#roads', {
                        language: languageFr,
                        pagingType: 'simple_numbers',
                        layout: {
                            topStart: null,
                            bottomEnd: {
                                paging: {
                                    numbers: 3
                                }
                            }
                        }
                    });
                }
            }).catch(function (error) {
                console.error(error);
                setService({ status: "Error" });
                setInfo(error.message);
            });
        return () => {
            mounted.current = false;
        }
    }, [])

    return (
        <div className="form road-table ajax-wrap">
            <div className={`${props.formBgColorClass} ${props.formColorLabel}-text`}>
                <div className="form-inner">
                    <div className="container">
                        <fieldset>
                            <div className="form-group col-12">
                                {service.status === "Loading" &&
                                    <div className="alert alert-info"><div className="info">{translate(dictionaryKeys.site.global.form.loading)}</div></div>
                                }
                                {service.status === "Error" &&
                                    <div className="alert alert-warning"><div className="info">{translate(dictionaryKeys.site.global.form.errorOccured)} {info}</div></div>
                                }
                                {service.status === "Empty" &&
                                    <div className="alert alert-info"><div className="info">{translate(dictionaryKeys.site.global.form.emptyData)}</div></div>
                                }
                            </div>

                            <table id="roads" className="table table-striped" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>{translate(dictionaryKeys.site.roadTable.num)}</th>
                                        <th>{translate(dictionaryKeys.site.roadTable.dest)}</th>
                                        <th>{translate(dictionaryKeys.site.roadTable.typ)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {service.status === "Loaded" &&
                                        road!.map((r: RoadTable) => (
                                            <tr>
                                                <td>{r.Route}</td>
                                                <td>{r.Destination}</td>
                                                <td>{r.Type}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    );
}

WidgetHelper.register(RoadTableWidget, 'road-table');