import React, { RefObject, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import { translate, WidgetHelper } from '../Helpers';
import * as Yup from 'yup';
import { DaycareForm, Daycare, Schedule } from '../Models';
import ReCAPTCHA from 'react-google-recaptcha';
import { dictionaryKeys, constants } from '../Constants';

interface DaycareFormProps {
    formBgColorClass: string;
    formColorLabel: string;
    formButtonColorLabel: string;
    formButtonColorClass: string;
    formButtonHoverColorClass: string;
    formDaycareOptions: string[];
    googleRecaptchaSiteKey: string;
    captchaDataSize: "compact" | "normal" | "invisible" | undefined;
    globalSettingsId: string;
    contactRecipientEmailAddress: string;
}

export function DaycareFormWidget(props: DaycareFormProps) {

    const recaptchaRef: RefObject<ReCAPTCHA> = React.createRef();
    const validationSchema = useRef(
        Yup.object({
            childsFullName: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            address: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            birthday: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            phoneNumber: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            email: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField))
                .email(translate(dictionaryKeys.site.global.form.invalidEmail)),
            admittanceDate: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            daysPerWeek: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            before7AM: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            after6PM: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            saturday: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            schedule: Yup.array().of(
                Yup.object().shape({
                    start: Yup.string().required(translate(dictionaryKeys.site.global.form.requiredField)).matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, "Le format n'est pas correct"),
                    end: Yup.string().required(translate(dictionaryKeys.site.global.form.requiredField)).matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, "Le format n'est pas correct"),
                })).min(1, translate(dictionaryKeys.site.daycareForm.scheduleError)),
            daycare: Yup.array().of(
                Yup.object().shape({
                    label: Yup.string().required(translate(dictionaryKeys.site.global.form.requiredField)),
                })).min(1, translate(dictionaryKeys.site.global.form.requiredField)),
        }),

    );

    const [callDate, setCallDate] = useState<string>();
    const [fathersFullName, setFathersFullName] = useState<string>();
    const [fathersJob, setFathersJob] = useState<string>();
    const [fathersJobPlace, setFathersJobPlace] = useState<string>();
    const [mothersFullName, setMothersFullName] = useState<string>();
    const [mothersJob, setMothersJob] = useState<string>();
    const [mothersJobPlace, setMothersJobPlace] = useState<string>();
    const [childsFullName, setChildsFullName] = useState<string>();
    const [birthday, setBirthday] = useState<string>();
    const [admittanceDate, setAdmittanceDate] = useState<string>();
    const [daysPerWeek, setDaysPerWeek] = useState<string>();
    const [before7AM, setBefore7AM] = useState<string>();
    const [after6PM, setAfter6PM] = useState<string>();
    const [saturday, setSaturday] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [address, setAddress] = useState<string>();
    const [daycare, setDaycare] = useState<Daycare[]>([]);
    const [schedule, setSchedule] = useState<Schedule[]>([]);
    const [remarks, setRemarks] = useState<string>();

    const [callDateError, setCallDateError] = useState<string>();
    const [childsFullNameError, setChildsFullNameError] = useState<string>();
    const [addressError, setAddressError] = useState<string>();
    const [birthdayError, setBirthdayError] = useState<string>();
    const [phoneNumberError, setPhoneNumberError] = useState<string>();
    const [emailError, setEmailError] = useState<string>();
    const [admittanceDateError, setAdmittanceDateError] = useState<string>();
    const [daycareError, setDaycareError] = useState<string>();
    const [before7AMError, setBefore7AMError] = useState<string>();
    const [after6PMError, setAfter6PMError] = useState<string>();
    const [saturdayError, setSaturdayError] = useState<string>();
    const [scheduleError, setScheduleError] = useState<string>();
    const [daysPerWeekError, setDaysPerWeekError] = useState<string>();

    const [recaptchaError, setRecaptchaError] = useState<string>();
    const [recaptchaToken, setRecaptchaToken] = useState<string>();
    const [statusMessage, setStatusMessage] = useState<string>();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    const Placeholder = props => {
        return <components.Placeholder {...props} />;
    };

    function executeApiRequest() {
        const formatedDataHeaders = new Headers();
        formatedDataHeaders.append("Accept-Language", document.querySelector("html")!.lang);
        formatedDataHeaders.append("Content-Type", "application/json");
        fetch(
            "/umbraco/api/contact/senddaycarerequest",
            {
                headers: formatedDataHeaders,
                method: "post",
                body: JSON.stringify(getDaycareFormModel()),
            })
            .then((response) => {
                if (response.ok) {
                    setStatusMessage(translate(dictionaryKeys.site.global.form.successMessage));
                    setIsSuccess(true);
                    setIsError(false);
                }
                else {
                    setStatusMessage(translate(dictionaryKeys.site.global.form.errorOccured));
                    setIsSuccess(false);
                    setIsError(true);
                }
            });
    }

    function getDaycareFormModel(): DaycareForm {
        return {
            callDate: callDate,
            fathersFullName: fathersFullName,
            fathersJob: fathersJob,
            fathersJobPlace: fathersJobPlace,
            mothersFullName: mothersFullName,
            mothersJob: mothersJob,
            mothersJobPlace: mothersJobPlace,
            childsFullName: childsFullName,
            birthday: birthday,
            admittanceDate: admittanceDate,
            daysPerWeek: daysPerWeek,
            before7AM: before7AM,
            after6PM: after6PM,
            saturday: saturday,
            address: address,
            email: email,
            phoneNumber: phoneNumber,
            daycare: daycare,
            schedule: schedule,
            remarks: remarks,
            recaptchaValue: recaptchaToken,
            globalSettingsId: props.globalSettingsId,
            contactRecipientEmailAddress: props.contactRecipientEmailAddress,
        }
    }

    function handleSubmit(e: any) {
        e.preventDefault();
        resetErrorMessages();
        resetSuccesInfo();

        if (props.googleRecaptchaSiteKey && !recaptchaToken) {
            resetRecatpcha();
            setRecaptchaError(translate(dictionaryKeys.site.global.form.requiredField))
            return;
        }

        validationSchema.current.validate(getDaycareFormModel(), { abortEarly: false })
            .then(() => {
                executeApiRequest();
            })
            .catch((validationError: Yup.ValidationError) => {
                resetRecatpcha()
                manageErrors(validationError);
            });
    }

    function resetRecatpcha() {
        recaptchaRef.current && recaptchaRef.current.reset();
    }

    function manageErrors(validationError: Yup.ValidationError) {
        validationError.inner.forEach((error: Yup.ValidationError) => {
            if (error.path!.startsWith("schedule")) {
                setScheduleError(error.message);
            } else {
                switch (error.path) {
                    case "callDate":
                        setCallDateError(error.message);
                        break;
                    case "phoneNumber":
                        setPhoneNumberError(error.message);
                        break;
                    case "address":
                        setAddressError(error.message);
                        break;
                    case "birthday":
                        setBirthdayError(error.message);
                        break;
                    case "childsFullName":
                        setChildsFullNameError(error.message);
                        break;
                    case "email":
                        setEmailError(error.message);
                        break;
                    case "admittanceDate":
                        setAdmittanceDateError(error.message);
                        break;
                    case "daycare":
                        setDaycareError(error.message);
                        break;
                    case "before7AM":
                        setBefore7AMError(error.message);
                        break;
                    case "after6PM":
                        setAfter6PMError(error.message);
                        break;
                    case "saturday":
                        setSaturdayError(error.message);
                        break;
                    case "daysPerWeek":
                        setDaysPerWeekError(error.message);
                        break;
                }
            }
        });
    }

    function resetErrorMessages() {
        setCallDateError(undefined);
        setPhoneNumberError(undefined);
        setAddressError(undefined);
        setBirthdayError(undefined);
        setChildsFullNameError(undefined);
        setEmailError(undefined);
        setAdmittanceDateError(undefined);
        setDaycareError(undefined);
        setScheduleError(undefined);
        setBefore7AMError(undefined);
        setAfter6PMError(undefined);
        setSaturdayError(undefined);
        setDaysPerWeekError(undefined);
        setRecaptchaError(undefined);
    }

    function resetSuccesInfo() {
        setIsError(false);
        setIsSuccess(false);
        setStatusMessage(undefined);
    }

    function handleRecaptchaChange(recaptchaToken: string) {
        setRecaptchaToken(recaptchaToken);
    }

    let options = props.formDaycareOptions.map(function (type, index) {
        return { value: index, label: type };
    });

    var handleChange = (option:any) => {
        setDaycare(option);
        return option;
    };

    var setMySchedule = (index: number, startend: string, val: string, day: string) => {
        let s = schedule.findIndex(item => item.index == index);
        if (s == -1) {
            if (startend == 'start') {

                schedule.push({ index: index, day: day, start: val, end: '' });
            }
            else {
                schedule.push({ index: index, day: day, start: '', end: val });
            }
        }
        else {
            if (startend == 'start') {
                schedule[s].start = val;
            }
            else {
                schedule[s].end = val;
            }
        }

        let filteredArray = schedule.filter(item => item.start != "" || item.end != "").sort((a, b) => a.index - b.index);
      
        setSchedule(filteredArray);
        return schedule;
    };

    function pad2(n: number) {
        return (n < 10 ? '0' : '') + n;
    }

    var getDate = () => {
        var date = new Date();
        var month = pad2(date.getMonth() + 1);
        var day = pad2(date.getDate());
        var year = date.getFullYear();

        return year + "-" + month + "-" + day;
    };

    return (
        <div className="form daycare-form ajax-wrap">
            <div className={`inner ${props.formBgColorClass} ${props.formColorLabel}-text`}>
                <form onSubmit={handleSubmit}>
                    <div className="inner-form">
                        <div className="container">
                            <fieldset>
                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="callDate" className="control-label">{translate(dictionaryKeys.site.daycareForm.callDate)} <em className="req">*</em></label>
                                        <input type="date" placeholder="jj/mm/aaaa" defaultValue={getDate()} readOnly={false} className="form-control" onChange={event => setCallDate(event.target.value)} />
                                        {!!callDateError &&
                                            <span className="field-validation-error">{callDateError}</span>
                                        }
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="fathersFullName" className="control-label">{translate(dictionaryKeys.site.daycareForm.fathersFullName)} </label>
                                        <input type="text" className="form-control" id="fathersFullName" onChange={event => setFathersFullName(event.target.value)} />
                                    </div>
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="mothersFullName" className="control-label">{translate(dictionaryKeys.site.daycareForm.mothersFullName)} </label>
                                        <input type="text" className="form-control" id="mothersFullName" onChange={event => setMothersFullName(event.target.value)} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="fathersJob" className="control-label">{translate(dictionaryKeys.site.daycareForm.fathersJob)} </label>
                                        <input type="text" className="form-control" id="fathersJob" onChange={event => setFathersJob(event.target.value)} />
                                    </div>
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="mothersJob" className="control-label">{translate(dictionaryKeys.site.daycareForm.mothersJob)} </label>
                                        <input type="text" className="form-control" id="mothersJob" onChange={event => setMothersJob(event.target.value)} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="fathersJobPlace" className="control-label">{translate(dictionaryKeys.site.daycareForm.fathersJobPlace)} </label>
                                        <input type="text" className="form-control" id="fathersJobPlace" onChange={event => setFathersJobPlace(event.target.value)} />
                                    </div>
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="mothersJobPlace" className="control-label">{translate(dictionaryKeys.site.daycareForm.mothersJobPlace)} </label>
                                        <input type="text" className="form-control" id="mothersJobPlace" onChange={event => setMothersJobPlace(event.target.value)} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="childsFullName" className="control-label">{translate(dictionaryKeys.site.daycareForm.childsFullName)} <em className="req">*</em></label>
                                        <input type="text" className="form-control" id="childsFullName" onChange={event => setChildsFullName(event.target.value)} />
                                        {!!childsFullNameError &&
                                            <span className="field-validation-error">{childsFullNameError}</span>
                                        }
                                    </div>

                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="birthday" className="control-label">{translate(dictionaryKeys.site.daycareForm.birthday)} <em className="req">*</em></label>
                                        <input type="date" placeholder="jj/mm/aaaa" className="form-control" id="birthDate" onChange={event => setBirthday(event.target.value)} />
                                        {!!birthdayError &&
                                            <span className="field-validation-error">{birthdayError}</span>
                                        }
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="address" className="control-label">{translate(dictionaryKeys.site.daycareForm.address)} <em className="req">*</em></label>
                                        <input className="form-control" id="address" onChange={event => setAddress(event.target.value)} />
                                        {!!addressError &&
                                            <span className="field-validation-error">{addressError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="email" className="control-label">{translate(dictionaryKeys.site.daycareForm.email)} <em className="req">*</em></label>
                                        <input type="email" autoCapitalize="off" className="form-control" id="email" onChange={event => setEmail(event.target.value)} />
                                        {!!emailError &&
                                            <span className="field-validation-error">{emailError}</span>
                                        }
                                    </div>
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="phoneNumber" className="control-label">{translate(dictionaryKeys.site.daycareForm.phoneNumber)} <em className="req">*</em></label>
                                        <input type="tel" className="form-control" id="phoneNumber" onChange={event => setPhoneNumber(event.target.value)} />
                                        {!!phoneNumberError &&
                                            <span className="field-validation-error">{phoneNumberError}</span>
                                        }
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="admittanceDate" className="control-label">{translate(dictionaryKeys.site.daycareForm.admittanceDate)} <em className="req">*</em></label>
                                        <input type="date" placeholder="jj/mm/aaaa" className="form-control" id="admittanceDate" onChange={event => setAdmittanceDate(event.target.value)} />
                                        {!!admittanceDateError &&
                                            <span className="field-validation-error">{admittanceDateError}</span>
                                        }
                                    </div>
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="daysPerWeek" className="control-label">{translate(dictionaryKeys.site.daycareForm.daysPerWeek)} <em className="req">*</em></label>
                                        <input type="number" min="1" max="6" className="form-control" id="daysPerWeek" onChange={event => setDaysPerWeek(event.target.value)} />
                                        {!!daysPerWeekError &&
                                            <span className="field-validation-error">{daysPerWeekError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="daycare" className="control-label">{translate(dictionaryKeys.site.daycareForm.daycare)} <em className="req">*</em></label>
                                        <Select
                                            components={{ Placeholder }}
                                            placeholder={'Sélectionnez au moins 1 crèche ...'}
                                            name="daycare"
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            options={options}
                                            onChange={handleChange}
                                            isMulti={true}
                                        />

                                        {!!daycareError &&
                                            <span className="field-validation-error">{daycareError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        {translate(dictionaryKeys.site.global.form.day)}
                                                    </th>
                                                    <th scope="col">
                                                        {translate(dictionaryKeys.site.daycareForm.arrivalTime)}
                                                    </th>
                                                    <th scope="col">
                                                        {translate(dictionaryKeys.site.daycareForm.departureTime)}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <label className="control-label">{translate(dictionaryKeys.site.global.form.days.monday)} </label>
                                                    </td>
                                                    <td>
                                                        <input type="text" maxLength={5} placeholder={constants.site.global.form.hhmm_ph} className="form-control" id="start" onBlur={(evt) => setMySchedule(1, evt.target.id, evt.target.value, translate(dictionaryKeys.site.global.form.days.monday))} />
                                                    </td>
                                                    <td>
                                                        <input type="text" maxLength={5} placeholder={constants.site.global.form.hhmm_ph} className="form-control" id="end" onBlur={(evt) => setMySchedule(1, evt.target.id, evt.target.value, translate(dictionaryKeys.site.global.form.days.monday))} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label className="control-label">{translate(dictionaryKeys.site.global.form.days.tuesday)} </label>
                                                    </td>
                                                    <td>
                                                        <input type="text" maxLength={5} placeholder={constants.site.global.form.hhmm_ph} className="form-control" id="start" onBlur={(evt) => setMySchedule(2, evt.target.id, evt.target.value, translate(dictionaryKeys.site.global.form.days.tuesday))} />
                                                    </td>
                                                    <td>
                                                        <input type="text" maxLength={5} placeholder={constants.site.global.form.hhmm_ph} className="form-control" id="end" onBlur={(evt) => setMySchedule(2, evt.target.id, evt.target.value, translate(dictionaryKeys.site.global.form.days.tuesday))} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label className="control-label">{translate(dictionaryKeys.site.global.form.days.wednesday)} </label>
                                                    </td>
                                                    <td>
                                                        <input type="text" maxLength={5} placeholder={constants.site.global.form.hhmm_ph} className="form-control" id="start" onBlur={(evt) => setMySchedule(3, evt.target.id, evt.target.value, translate(dictionaryKeys.site.global.form.days.wednesday))} />
                                                    </td>
                                                    <td>
                                                        <input type="text" maxLength={5} placeholder={constants.site.global.form.hhmm_ph} className="form-control" id="end" onBlur={(evt) => setMySchedule(3, evt.target.id, evt.target.value, translate(dictionaryKeys.site.global.form.days.wednesday))} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label className="control-label">{translate(dictionaryKeys.site.global.form.days.thursday)} </label>
                                                    </td>
                                                    <td>
                                                        <input type="text" maxLength={5} placeholder={constants.site.global.form.hhmm_ph} className="form-control" id="start" onBlur={(evt) => setMySchedule(4, evt.target.id, evt.target.value, translate(dictionaryKeys.site.global.form.days.thursday))} />
                                                    </td>
                                                    <td>
                                                        <input type="text" maxLength={5} placeholder={constants.site.global.form.hhmm_ph} className="form-control" id="end" onBlur={(evt) => setMySchedule(4, evt.target.id, evt.target.value, translate(dictionaryKeys.site.global.form.days.thursday))} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label className="control-label">{translate(dictionaryKeys.site.global.form.days.friday)} </label>
                                                    </td>
                                                    <td>
                                                        <input type="text" maxLength={5} placeholder={constants.site.global.form.hhmm_ph} className="form-control" id="start" onBlur={(evt) => setMySchedule(5, evt.target.id, evt.target.value, translate(dictionaryKeys.site.global.form.days.friday))} />
                                                    </td>
                                                    <td>
                                                        <input type="text" maxLength={5} placeholder={constants.site.global.form.hhmm_ph} className="form-control" id="end" onBlur={(evt) => setMySchedule(5, evt.target.id, evt.target.value, translate(dictionaryKeys.site.global.form.days.friday))} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label className="control-label">{translate(dictionaryKeys.site.global.form.days.saturday)} </label>
                                                    </td>
                                                    <td>
                                                        <input type="text" maxLength={5} placeholder={constants.site.global.form.hhmm_ph} className="form-control" id="start" onBlur={(evt) => setMySchedule(6, evt.target.id, evt.target.value, translate(dictionaryKeys.site.global.form.days.saturday))} />
                                                    </td>
                                                    <td>
                                                        <input type="text" maxLength={5} placeholder={constants.site.global.form.hhmm_ph} className="form-control" id="end" onBlur={(evt) => setMySchedule(6, evt.target.id, evt.target.value, translate(dictionaryKeys.site.global.form.days.saturday))} />
                                                    </td>
                                                </tr>

                                            </tbody>

                                        </table>
                                        {!!scheduleError &&
                                            <span className="field-validation-error">{scheduleError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-4">
                                        <label className="control-label">{translate(dictionaryKeys.site.daycareForm.before7AM)} <em className="req">*</em></label>
                                        <div className="form-group radio">
                                            <div className="form-control">
                                                <div className="radioRow">
                                                    <input id="by" name="before7AM" type="radio" value={translate(dictionaryKeys.site.global.form.yes)} onChange={event => setBefore7AM(event.target.value)} />
                                                    <label htmlFor="by">{translate(dictionaryKeys.site.global.form.yes)}</label>
                                                </div>
                                                <div className="radioRow">
                                                    <input id="bn" name="before7AM" type="radio" value={translate(dictionaryKeys.site.global.form.no)} onChange={event => setBefore7AM(event.target.value)} />
                                                    <label htmlFor="bn">{translate(dictionaryKeys.site.global.form.no)}</label>
                                                </div>
                                            </div></div>
                                        {!!before7AMError &&
                                            <span className="field-validation-error">{before7AMError}</span>
                                        }
                                    </div>

                                    <div className="form-group col-4">
                                        <label className="control-label">{translate(dictionaryKeys.site.daycareForm.after6PM)} <em className="req">*</em></label>
                                        <div className="form-group radio">
                                            <div className="form-control">
                                                <div className="radioRow">
                                                    <input id="ay" name="after6PM" type="radio" value={translate(dictionaryKeys.site.global.form.yes)} onChange={event => setAfter6PM(event.target.value)} />
                                                    <label htmlFor="ay">{translate(dictionaryKeys.site.global.form.yes)}</label>
                                                </div>
                                                <div className="radioRow">
                                                    <input id="an" name="after6PM" type="radio" value={translate(dictionaryKeys.site.global.form.no)} onChange={event => setAfter6PM(event.target.value)} />
                                                    <label htmlFor="an">{translate(dictionaryKeys.site.global.form.no)}</label>
                                                </div>
                                            </div></div>
                                        {!!after6PMError &&
                                            <span className="field-validation-error">{after6PMError}</span>
                                        }
                                    </div>

                                    <div className="form-group col-4">
                                        <label className="control-label">{translate(dictionaryKeys.site.daycareForm.saturday)} <em className="req">*</em></label>
                                        <div className="form-group radio">
                                            <div className="form-control">
                                                <div className="radioRow">
                                                    <input id="saty" name="saturday" type="radio" value={translate(dictionaryKeys.site.global.form.yes)} onChange={event => setSaturday(event.target.value)} />
                                                    <label htmlFor="saty">{translate(dictionaryKeys.site.global.form.yes)}</label>
                                                </div>
                                                <div className="radioRow">
                                                    <input id="satn" name="saturday" type="radio" value={translate(dictionaryKeys.site.global.form.no)} onChange={event => setSaturday(event.target.value)} />
                                                    <label htmlFor="satn">{translate(dictionaryKeys.site.global.form.no)}</label>
                                                </div>
                                            </div></div>
                                        {!!saturdayError &&
                                            <span className="field-validation-error">{saturdayError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="remarks" className="control-label">{translate(dictionaryKeys.site.daycareForm.remarks)}</label>
                                        <textarea className="form-control" id="remarks" onChange={event => setRemarks(event.target.value)}></textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-5 col-xs-5">
                                        {!!(props.googleRecaptchaSiteKey && props.captchaDataSize) &&
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                size={props.captchaDataSize}
                                                sitekey={props.googleRecaptchaSiteKey}
                                                onChange={handleRecaptchaChange}
                                            />
                                        }
                                        {!!recaptchaError &&
                                            <span className="field-validation-error">{recaptchaError}</span>
                                        }
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="buttons">
                                <button role="button"
                                    className={`btn ${props.formButtonColorClass} ${props.formButtonHoverColorClass}
                                              ${props.formButtonColorLabel}-btn-text ${props.formButtonColorLabel}-btn-borders`}
                                    type="submit">
                                    {translate(dictionaryKeys.site.global.form.submit)}
                                </button>
                            </fieldset>
                        </div>
                        {isSuccess &&
                            <div className="alert alert-success"><div className="info">{statusMessage}</div></div>
                        }
                        {isError &&
                            <div className="alert alert-warning"><div className="info">{statusMessage}</div></div>
                        }
                    </div>
                </form>
            </div>
        </div>
    );
}

WidgetHelper.register(DaycareFormWidget, 'daycare-form');