import React, { RefObject, useRef, useState } from 'react';
import { translate, WidgetHelper } from '../Helpers';
import * as Yup from 'yup';
import { ContactForm } from '../Models';
import ReCAPTCHA from 'react-google-recaptcha';
import { dictionaryKeys } from '../Constants';

interface ContactFormProps {
    formBgColorClass: string;
    formColorLabel: string;
    formButtonColorLabel: string;
    formButtonColorClass: string;
    formButtonHoverColorClass: string;
    googleRecaptchaSiteKey: string;
    captchaDataSize: "compact" | "normal" | "invisible" | undefined;
    globalSettingsId: string;
    contactRecipientEmailAddress: string;
    contactRecipientEmailAddressPara: string;
}

export function ContactFormWidget(props: ContactFormProps) {

    const recaptchaRef: RefObject<ReCAPTCHA> = React.createRef();
    const validationSchema = useRef(
        Yup.object({
            firstName: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            lastName: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            message: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField)),
            email: Yup.string()
                .required(translate(dictionaryKeys.site.global.form.requiredField))
                .email(translate(dictionaryKeys.site.global.form.invalidEmail)),
            phone: Yup.string().notRequired(),
            address: Yup.string().notRequired(),
            zipCode: Yup.string().notRequired(),
            town: Yup.string().notRequired(),
        })
    );

    const [message, setMessage] = useState<string>();
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [phone, setPhone] = useState<string>();
    const [address, setAddress] = useState<string>();
    const [zipCode, setZipCode] = useState<string>();
    const [town, setTown] = useState<string>();

    const [messageError, setMessageError] = useState<string>();
    const [firstNameError, setFirstNameError] = useState<string>();
    const [lastNameError, setLastNameError] = useState<string>();
    const [emailError, setEmailError] = useState<string>();

    const [recaptchaError, setRecaptchaError] = useState<string>();
    const [recaptchaToken, setRecaptchaToken] = useState<string>();
    const [statusMessage, setStatusMessage] = useState<string>();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);


    function executeApiRequest() {
        const formatedDataHeaders = new Headers();
        formatedDataHeaders.append("Accept-Language", document.querySelector("html")!.lang);
        formatedDataHeaders.append("Content-Type", "application/json");
        fetch(
            "/umbraco/api/contact/sendcontactrequest",
            {
                headers: formatedDataHeaders,
                method: "post",
                body: JSON.stringify(getContactFormModel()),
            })
            .then((response) => {
                if (response.ok) {
                    setStatusMessage(translate(dictionaryKeys.site.global.form.successMessage));
                    setIsSuccess(true);
                    setIsError(false);
                }
                else {
                    setStatusMessage(translate(dictionaryKeys.site.global.form.errorOccured));
                    setIsSuccess(false);
                    setIsError(true);
                }
            });
    }

    function getContactFormModel(): ContactForm {
        return {
            message: message,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            address: address,
            zipCode: zipCode,
            town: town,
            recaptchaValue: recaptchaToken,
            globalSettingsId: props.globalSettingsId,
            contactRecipientEmailAddress: props.contactRecipientEmailAddress
        }
    }

    function handleSubmit(e: any) {
        e.preventDefault();
        resetErrorMessages();
        resetSuccesInfo();

        if (props.googleRecaptchaSiteKey && !recaptchaToken) {
            resetRecatpcha();
            setRecaptchaError(translate(dictionaryKeys.site.global.form.requiredField))
            return;
        }

        validationSchema.current.validate(getContactFormModel(), { abortEarly: false })
            .then(() => 
            {
                executeApiRequest();
            })
            .catch((validationError: Yup.ValidationError) => {
                resetRecatpcha()
                manageErrors(validationError);
            });
    }

    function resetRecatpcha() {
        recaptchaRef.current && recaptchaRef.current.reset();
    }

    function manageErrors(validationError: Yup.ValidationError) {
        validationError.inner.forEach((error: Yup.ValidationError) => {
            switch (error.path) {
                case "message":
                    setMessageError(error.message);
                    break;
                case "firstName":
                    setFirstNameError(error.message);
                    break;
                case "lastName":
                    setLastNameError(error.message);
                    break;
                case "email":
                    setEmailError(error.message);
                    break;
            }
        });
    }

    function resetErrorMessages() {
        setFirstNameError(undefined);
        setLastNameError(undefined);
        setMessageError(undefined);
        setEmailError(undefined);
        setRecaptchaError(undefined);
    }

    function resetSuccesInfo() {
        setIsError(false);
        setIsSuccess(false);
        setStatusMessage(undefined);
    }

    function handleRecaptchaChange(recaptchaToken: string) {
        setRecaptchaToken(recaptchaToken);
    }

    return (
        <div className="form contact-form ajax-wrap">
            <div className={`inner ${props.formBgColorClass} ${props.formColorLabel}-text`}>
                <form onSubmit={handleSubmit}>
                    <div className="form-inner">
                        <div className="container">
                            <fieldset>
                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="firstName" className="control-label">{translate(dictionaryKeys.site.contactForm.lastName)} <em className="req">*</em></label>
                                        <input type="text" className="form-control" id="firstName" onChange={event => setFirstName(event.target.value)} />
                                        {!!firstNameError &&
                                            <span className="field-validation-error">{firstNameError}</span>
                                        }
                                    </div>
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="lastName" className="control-label">{translate(dictionaryKeys.site.contactForm.firstName)} <em className="req">*</em></label>
                                        <input type="text" className="form-control" id="lastName" onChange={event => setLastName(event.target.value)} />
                                        {!!lastNameError &&
                                            <span className="field-validation-error">{lastNameError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="email" className="control-label">{translate(dictionaryKeys.site.contactForm.email)} <em className="req">*</em></label>
                                        <input type="email" autoCapitalize="off" className="form-control" id="email" onChange={event => setEmail(event.target.value)} />
                                        {!!emailError &&
                                            <span className="field-validation-error">{emailError}</span>
                                        }
                                    </div>
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="phone" className="control-label">{translate(dictionaryKeys.site.contactForm.phone)}</label>
                                        <input type="tel" className="form-control" id="phone" onChange={event => setPhone(event.target.value)} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="address" className="control-label">{translate(dictionaryKeys.site.contactForm.address)}</label>
                                        <input className="form-control" id="address" onChange={event => setAddress(event.target.value)} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="zipCode" className="control-label">{translate(dictionaryKeys.site.contactForm.zipCode)}</label>
                                        <input className="form-control" id="zipCode" onChange={event => setZipCode(event.target.value)}></input>
                                    </div>
                                    <div className="form-group col-md-6 col-xs-12">
                                        <label htmlFor="town" className="control-label">{translate(dictionaryKeys.site.contactForm.town)}</label>
                                        <input className="form-control" id="town" onChange={event => setTown(event.target.value)}></input>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="message" className="control-label">{translate(dictionaryKeys.site.contactForm.message)} <em className="req">*</em></label>
                                        <textarea className="form-control" id="message" onChange={event => setMessage(event.target.value)}></textarea>
                                        {!!messageError &&
                                            <span className="field-validation-error">{messageError}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-5 col-xs-5">
                                        {!!(props.googleRecaptchaSiteKey && props.captchaDataSize) &&
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                size={props.captchaDataSize}
                                                sitekey={props.googleRecaptchaSiteKey}
                                                onChange={handleRecaptchaChange}
                                            />
                                        }
                                        {!!recaptchaError &&
                                            <span className="field-validation-error">{recaptchaError}</span>
                                        }
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="buttons">
                                <button role="button"
                                    className={`btn ${props.formButtonColorClass} ${props.formButtonHoverColorClass}
                                              ${props.formButtonColorLabel}-btn-text ${props.formButtonColorLabel}-btn-borders`}
                                    type="submit">
                                {translate(dictionaryKeys.site.global.form.submit)}
                                </button>
                            </fieldset>
                        </div>
                        {isSuccess && 
                            <div className="alert alert-success"><div className="info">{statusMessage}</div></div>
                        }
                        {isError && 
                            <div className="alert alert-warning"><div className="info">{statusMessage}</div></div>
                        }
                    </div>
                </form>
            </div>
        </div>
    );
}

WidgetHelper.register(ContactFormWidget, 'contact-form');