export interface Day {
    day: string;
    abrev: string;
}
export interface PrestatairePeriode {
    prestataire: Prestataire;
    periodes: Periode[];
}

export interface Periode {
    jour: number;
    jourDescription: string;
    tranche: number;
    trancheDescription: string;
}

export interface Prestataire {
    id: number;
    description: string;
    isAgent: boolean;
    fonction: string;
    isConventionne: boolean;
}

export interface Session {
    prestataire: Prestataire;
    periode: Periode;
}

export interface Telecom {
    description: string;
}

export interface SiteSession {
    id: number;
    description: string;
    telecoms: Telecom[];
    sessions: Session[];
}

export interface ConsultService {
    idDisciplineMedicale: string;
    descriptionDisciplineMedicale: string;
    sitesSessions: SiteSession[];
}