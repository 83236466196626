import { RecaptchaForm } from './RecaptchaForm';

export interface JobForm extends RecaptchaForm {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    sector?: string;
    message?: string;
    cv?: string;
    motivation?: string;
    jobType?: string;
    cvInfo?: string;
    motivationInfo?: string;
    contactRecipientEmailAddress?: string;
    contactRecipientEmailAddressPara?: string;
}